import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import * as _ from "lodash";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { userActions } from "../../actions";
import Container from "../../common/Container";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
  password: yup
    .string()
    .required("Please specify your password")
    .min(8, "The password should have at minimum length of 8"),
});

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  // reset login status
  useEffect(() => {
    dispatch(userActions.reset());
  }, []);

  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = (values) => {
    return values;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const handleSubmit = (e) => {
    formik.handleSubmit();
    e.preventDefault();

    const userLogin = {
      email: formik.values.email.trim(),
      password: formik.values.password.trim(),
    };

    if (
      _.isEmpty(formik.errors) &&
      !_.isEmpty(userLogin.email) &&
      !_.isEmpty(userLogin.password)
    ) {
      dispatch(userActions.login(userLogin));
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(showPassword ? false : true);
  };

  const ColorButton = styled(Button)(() => ({
    color: "#fff",
    width: 150,
    height: 55,
    fontWeight: 600,
    fontSize: "18px",
    textTransform: "capitalize",
    backgroundColor: "#049593",
    borderColor: "#049593",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#1C4E63",
      borderColor: "#1C4E63",
    },
  }));
  return (
    <Box
      position={"relative"}
      minHeight={"80vh"}
      display={"flex"}
      marginLeft={{ xs: "0%", sm: "5%" }}
    >
      <Box
        flex={{ xs: "1 1 100%", md: "1 1 70%" }}
        maxWidth={{ xs: "100%", md: "35%" }}
      >
        <Box display={"flex"} alignItems={"center"} height={"100%"}>
          <Container maxWidth={800}>
            <Box>
              <Box marginBottom={4}>
                <Typography
                  variant="h4"
                  color="#049593"
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  Admin Portal
                </Typography>
              </Box>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={4} elevation={2}>
                  <Grid item xs={12}>
                    <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
                      Email
                    </Typography>
                    <TextField
                      variant="outlined"
                      name={"email"}
                      fullWidth
                      required
                      placeholder="Enter your email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
                      Password
                    </Typography>
                    <FormControl
                      required
                      sx={{ width: "100%" }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        name={"password"}
                        type={showPassword ? "text" : "password"}
                        fullWidth
                        placeholder="Enter your password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Box
                      display="flex"
                      flexDirection={{ xs: "column", sm: "row" }}
                      alignItems={{ xs: "center", sm: "center" }}
                      justifyContent={"space-between"}
                      width={"100%"}
                      marginLeft={{ xs: "0%", sm: "0%" }}
                    >
                      <ColorButton
                        variant="outlined"
                        size="medium"
                        type={"submit"}
                      >
                        Login
                      </ColorButton>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(Login);
