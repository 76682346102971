import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  Paper,
  Select,
  FormControl,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { Formik, Form, Field } from "formik";
import { uiInfoService } from "../../../services";
import { useDispatch } from "react-redux";
import { scoreActions } from "../../../actions";
import * as _ from "lodash";

const UpdateStateAnalysis = ({ quarters, token }) => {
  const dispatch = useDispatch();

  const [selectedPeriod, setSelectedPeriod] = useState(quarters[0].period_id);
  const [analysisData, setAnalysisData] = useState([]);
  const [currentEditRow, setCurrentEditRow] = useState(null);
  const [currentEditRowIndex, setCurrentEditRowIndex] = useState(null);

  const fetchLeagueData = async (period_id) => {
    try {
      if (period_id) {
        const data = await uiInfoService.getAnalysisPeriodByID(period_id);
        setAnalysisData(data.period_id.states);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchLeagueData(selectedPeriod);
  }, [selectedPeriod]);

  const handleChangePeriod = (event) => {
    setSelectedPeriod(event.target.value);
  };

  const handleEditClick = (index, data) => {
    setCurrentEditRow(() => data);
    setCurrentEditRowIndex(() => index);
  };

  const handleSubmit = (values) => {
    const updateStateAnalysis = {
      analysis_id: values.analysis_id,
      state_analysis: values.state_analysis,
      period_id: selectedPeriod,
      state_id: values.state_id,
    };

    if (!_.isEmpty(updateStateAnalysis)) {
      dispatch(
        scoreActions.editStateAnalysis(
          token,
          updateStateAnalysis,
          values.analysis_id
        )
      );
    }
  };

  const handleCellChange = (e) => {
    const newValue = e.target.value;
    const newData = { ...currentEditRow };
    newData.state_analysis = newValue;
    setCurrentEditRow(newData);
  };

  const ColorButton = styled(Button)(() => ({
    color: "#fff",
    width: 140,
    height: 40,
    fontSize: "14px",
    textTransform: "capitalize",
    backgroundColor: "#049593",
    borderColor: "#049593",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#1C4E63",
      borderColor: "#1C4E63",
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#1C4E63",
      color: theme.palette.common.white,
      fontWeight: 700,
      minWidth: 50,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      minWidth: 170,
    },
  }));

  const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#1C4E63",
      color: theme.palette.common.white,
      fontWeight: 700,
      minWidth: 600,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <div>
      <Box>
        <Typography
          variant="h3"
          color={"#049593"}
          align={"left"}
          fontWeight={700}
          gutterBottom
          marginBottom={{ xs: "15%", sm: "2%" }}
        >
          Edit State Analysis
        </Typography>
        <Typography
          variant={"subtitle2"}
          sx={{ marginBottom: 1 }}
          fontWeight={700}
          color={"#1C4E63"}
        >
          Select Quarter
        </Typography>
        <Formik onSubmit={handleSubmit}>
          {(props) => (
            <Form>
              <Box
                marginBottom={"2%"}
                width={"25%"}
                marginLeft={{ xs: "0%", sm: "0%" }}
              >
                <FormControl required fullWidth>
                  <Select
                    labelId="quarter"
                    id="quarter"
                    name={"quarter"}
                    placeholder="Select Quarter"
                    value={selectedPeriod}
                    onChange={handleChangePeriod}
                    fullWidth
                  >
                    {quarters.map((periodId) => (
                      <MenuItem
                        key={periodId.period_id}
                        value={periodId.period_id}
                      >
                        {periodId.quarter}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box marginBottom={"2%"}>
                <Typography
                  variant="body1"
                  color="#B80000"
                  fontWeight={700}
                  gutterBottom
                >
                  * Edit the analysis of one State at a time
                </Typography>
                <Typography
                  variant="body1"
                  color="#B80000"
                  fontWeight={700}
                  gutterBottom
                >
                  * Save the edit of a State before starting another edit
                </Typography>
                <Typography
                  variant="body1"
                  color="#B80000"
                  fontWeight={700}
                  gutterBottom
                >
                  * Save all the edits in a quarter before switching to another
                  quarter
                </Typography>
              </Box>
              <TableContainer component={Paper}>
                <Table marginTop={"2%"}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">States</StyledTableCell>
                      <StyledTableCell2 align="center">
                        State Analysis
                      </StyledTableCell2>
                      <StyledTableCell align="center">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {analysisData.length === 0 ? (
                      <TableRow>
                        <StyledTableCell align="left" colSpan={3}>
                          <Typography
                            variant="h4"
                            color={"#049593"}
                            align="center"
                            fontWeight={700}
                          >
                            No State-by-State Appraisal
                          </Typography>
                        </StyledTableCell>
                      </TableRow>
                    ) : (
                      analysisData.map((state, index) => (
                        <StyledTableRow key={state.id}>
                          <TableCell>
                            <FormControl fullWidth>
                              <Select
                                labelId={`state-${state.state_id}`}
                                id={`state-${state.state_id}`}
                                name={`state-${state.state_id}`}
                                value={state.state_name}
                                inputProps={{ readOnly: true }}
                                fullWidth
                              >
                                <MenuItem
                                  key={state.state_id}
                                  value={state.state_name}
                                >
                                  {state.state_name}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            {currentEditRowIndex === index ? (
                              <Field
                                value={currentEditRow.state_analysis}
                                name={`states.${index}.state_analysis`}
                                as={TextField}
                                onChange={(e) => handleCellChange(e)}
                                multiline
                                rows={4}
                                fullWidth
                              />
                            ) : (
                              state.state_analysis
                            )}
                          </TableCell>
                          {currentEditRowIndex === index ? (
                            <Box
                              margin={"30% 0%"}
                              display={"flex"}
                              justifyContent={"center"}
                            >
                              <ColorButton
                                onClick={() =>
                                  handleSubmit(currentEditRow, state.state_id)
                                }
                              >
                                Save
                              </ColorButton>
                            </Box>
                          ) : (
                            <Box
                              margin={"30% 0%"}
                              display={"flex"}
                              justifyContent={"center"}
                            >
                              <ColorButton
                                onClick={() => handleEditClick(index, state)}
                              >
                                Edit
                              </ColorButton>
                            </Box>
                          )}
                        </StyledTableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

export default UpdateStateAnalysis;
