export const uiInfoConstants = {
  PERIOD_INFO_REQUEST: "PERIOD_INFO_REQUEST",
  PERIOD_INFO_SUCCESS: "PERIOD_INFO_SUCCESS",
  PERIOD_INFO_FAILURE: "PERIOD_INFO_FAILURE",

  QUARTER_INFO_REQUEST: "QUARTER_INFO_REQUEST",
  QUARTER_INFO_SUCCESS: "QUARTER_INFO_SUCCESS",
  QUARTER_INFO_FAILURE: "QUARTER_INFO_FAILURE",

  STATE_INFO_REQUEST: "STATE_INFO_REQUEST",
  STATE_INFO_SUCCESS: "STATE_INFO_SUCCESS",
  STATE_INFO_FAILURE: "STATE_INFO_FAILURE",

  LEAGUE_INFO_REQUEST: "LEAGUE_INFO_REQUEST",
  LEAGUE_INFO_SUCCESS: "LEAGUE_INFO_SUCCESS",
  LEAGUE_INFO_FAILURE: "LEAGUE_INFO_FAILURE",

  ANALYSIS_INFO_REQUEST: "ANALYSIS_INFO_REQUEST",
  ANALYSIS_INFO_SUCCESS: "ANALYSIS_INFO_SUCCESS",
  ANALYSIS_INFO_FAILURE: "ANALYSIS_INFO_FAILURE",
};
