import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { uiInfoActions } from "../../../actions";

const ColorButton = styled(Button)(() => ({
  color: "#fff",
  width: 140,
  height: 40,
  fontSize: "14px",
  textTransform: "capitalize",
  backgroundColor: "#049593",
  borderColor: "#049593",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#1C4E63",
    borderColor: "#1C4E63",
  },
}));

const ListQuarter = ({ token, quarters }) => {
  const [editIndex, setEditIndex] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);

  const dispatch = useDispatch();

  const handleEditClick = (index) => {
    setEditIndex(index);
    setSelectedValue(quarters[index].publish);
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const getPublishText = (isPublished) => {
    return isPublished ? "Published" : "Not Published";
  };

  const handleSaveClick = () => {
    if (editIndex !== null) {
      const editedQuarter = {
        ...quarters[editIndex],
        publish: selectedValue,
      };

      dispatch(uiInfoActions.editPublishQuarter(token, editedQuarter));

      setEditIndex(null);
      setSelectedValue(null);
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#1C4E63",
      color: theme.palette.common.white,
      fontWeight: 700,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      color: "#1C4E63",
      fontSize: 14,
    },
  }));

  return (
    <Box marginTop={{ xs: "15%", sm: "2%" }}>
      <Typography
        variant="h5"
        color={"#049593"}
        align={"left"}
        fontWeight={700}
        gutterBottom
        marginBottom={{ xs: "15%", sm: "2%" }}
      >
        List of Quarters
      </Typography>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">S/N</StyledTableCell>
              <StyledTableCell align="left">Quarters</StyledTableCell>
              <StyledTableCell align="left">Publish</StyledTableCell>
              <StyledTableCell align="left">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {quarters.map((quarter, index) => (
              <TableRow key={index}>
                <StyledTableCell2 align="left">{index + 1}</StyledTableCell2>
                <StyledTableCell2 align="left">
                  {quarter.quarter}
                </StyledTableCell2>
                <StyledTableCell2 align="left">
                  {editIndex === index ? (
                    <Select value={selectedValue} onChange={handleSelectChange}>
                      <MenuItem value={true}>Published</MenuItem>
                      <MenuItem value={false}>Not Published</MenuItem>
                    </Select>
                  ) : (
                    getPublishText(quarter.publish)
                  )}
                </StyledTableCell2>
                <StyledTableCell2 align="left">
                  {editIndex === index ? (
                    <ColorButton onClick={handleSaveClick}>Save</ColorButton>
                  ) : (
                    <ColorButton onClick={() => handleEditClick(index)}>
                      Edit
                    </ColorButton>
                  )}
                </StyledTableCell2>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ListQuarter;
