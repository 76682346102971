const budgitSnack = (state = {}, action) => {
  switch (action.type) {
    case "SNACKBAR_SUCCESS":
      return {
        ...state,
        successSnackbarOpen: true,
        successSnackbarMessage: action.message,
      };
    case "SNACKBAR_ERROR":
      return {
        ...state,
        errorSnackbarOpen: true,
        errorSnackbarMessage: action.message,
      };
    case "SNACKBAR_INFO":
      return {
        ...state,
        infoSnackbarOpen: true,
        infoSnackbarMessage: action.message,
      };
    case "SNACKBAR_WARNING":
      return {
        ...state,
        warningSnackbarOpen: true,
        warningSnackbarMessage: action.message,
      };
    case "SNACKBAR_CLEAR":
      return {
        ...state,
        successSnackbarOpen: false,
        errorSnackbarOpen: false,
        infoSnackbarOpen: false,
        warningSnackbarOpen: false,
      };
    default:
      return state;
  }
};

export default budgitSnack;
