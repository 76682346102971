import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import { Box, Link, Drawer, Typography, Avatar } from "@mui/material";
import useResponsive from "../../../../hooks/useResponsive";
import logo from "../../../../assets/logo/logo.png";
import Scrollbar from "../../../../common/scrollbar";
import NavSection from "../../../../common/nav-section";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PostAddIcon from "@mui/icons-material/PostAdd";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import QueueIcon from "@mui/icons-material/Queue";
// import WatchLaterIcon from "@mui/icons-material/WatchLater";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import EditNoteIcon from "@mui/icons-material/EditNote";

const navConfig = [
  {
    title: "Dashboard",
    path: "/dashboard/app",
    icon: <DashboardIcon fontSize="medium" />,
  },
  {
    title: "Add League Scores",
    path: "/dashboard/add-league-score",
    icon: <PostAddIcon fontSize="medium" />,
  },
  {
    title: "Edit League Scores",
    path: "/dashboard/edit-league-score",
    icon: <EditNoteIcon fontSize="medium" />,
  },
  {
    title: "Add State Analysis",
    path: "/dashboard/add-state-analysis",
    icon: <QueueIcon fontSize="medium" />,
  },
  {
    title: "Edit State Analysis",
    path: "/dashboard/edit-state-analysis",
    icon: <EditNoteIcon fontSize="medium" />,
  },
  {
    title: "Publish Quarter",
    path: "/dashboard/publish-quarter",
    icon: <MoreTimeIcon fontSize="medium" />,
  },
  // {
  //   title: "Add Quarter",
  //   path: "/dashboard/add-quarter",
  //   icon: <WatchLaterIcon fontSize="medium" />,
  // },
  {
    title: "Add User",
    path: "/dashboard/add-user",
    icon: <GroupAddIcon fontSize="medium" />,
  },
];

const NAV_WIDTH = 240;

const StyledAccount = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

Sidebar.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Sidebar({ openNav, onCloseNav }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: "inline-flex" }}>
        <Box
          component="a"
          underline="none"
          href="/dashboard/app"
          title="BudgIT"
        >
          <img src={logo} alt="logo" width={"60%"} />
        </Box>
      </Box>
      <Box sx={{ mb: 5, mx: 1.5 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar src={"/broken-image.jpg"} />

            <Box sx={{ ml: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{ color: "#049593" }}
                fontWeight={700}
              >
                {"Admin Panel"}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>
      <NavSection data={navConfig} />
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
