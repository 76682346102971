import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ children, ...rest }) => {
  const token = localStorage.getItem("budgit_token");

  if (token) {
    return <Route exact {...children.props} {...rest} render={rest.render} />;
  }

  return <Route {...rest} render={() => <Redirect to="/Login" />} />;
};

PrivateRoute.propTypes = {
  children: PropTypes.any,
};

export default PrivateRoute;
