import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { blue } from "@mui/material/colors";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Divider } from "@mui/material";
import HouseIcon from "@mui/icons-material/House";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import logo from "../../../../assets/logo/logo_2.png";
import Container from "../../../../common/Container";

const Footer = () => {
  return (
    <Container>
      <Grid container spacing={1} marginTop={5}>
        <Grid item xs={12} sm={6} md={12} marginBottom={2}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={{ xs: "100%", sm: "100%" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Box
              display="flex"
              flexWrap={"wrap"}
              alignItems={"left"}
              flexDirection={"column"}
              width={{ xs: "90%", sm: "35%" }}
              marginLeft={{ xs: "5%", sm: "0%" }}
            >
              <Box component="a" underline="none" href="/" title="BudgIT">
                <img src={logo} alt="logo" width={"60%"} />
              </Box>
              <Typography color="#fff" variant={"body2"} paddingTop={"2%"}>
                The initiative is a build up on the recently concluded World
                Bank's State Fiscal Transparency, Accountability and
                Sustainability (SFTAS) Program, which promoted fiscal
                transparency, and facilitated accountability in public resource
                management.
              </Typography>
            </Box>
            <Box
              display="flex"
              flexWrap={"wrap"}
              justifyContent={"space-between"}
              width={{ xs: "95%", sm: "100%" }}
              flexDirection={{ xs: "column", sm: "row" }}
              marginLeft={{ xs: "0%", sm: "5%" }}
              marginBottom={2}
            >
              <Box
                display="flex"
                flexWrap={"wrap"}
                flexDirection={"column"}
                marginTop={{ xs: "10%", sm: "0%" }}
                width={{ xs: "90%", sm: "30%" }}
              >
                <Typography
                  sx={{ textTransform: "capitalize", fontWeight: 700 }}
                  gutterBottom
                  color={"#fff"}
                  align={"left"}
                  variant={"body2"}
                  paddingBottom={{ xs: "5%", sm: "7%" }}
                >
                  Resources
                </Typography>
                <Link
                  underline="none"
                  component="a"
                  href="https://openstates.ng/"
                  color="#fff"
                  variant={"body2"}
                  fontWeight={500}
                  target={"_blank"}
                  paddingBottom={{ xs: "2%", sm: "2%" }}
                  lineHeight={2}
                  sx={{
                    "&:hover": {
                      color: "#ddd",
                    },
                  }}
                >
                  Openstates
                </Link>
                <Link
                  underline="none"
                  component="a"
                  href="https://stateofstates.yourbudgit.com/"
                  color="#fff"
                  variant={"body2"}
                  target={"_blank"}
                  fontWeight={500}
                  paddingBottom={{ xs: "2%", sm: "2%" }}
                  lineHeight={2}
                  sx={{
                    "&:hover": {
                      color: "#ddd",
                    },
                  }}
                >
                  State of States
                </Link>
                <Link
                  underline="none"
                  component="a"
                  href="https://www.govspend.ng/"
                  target={"_blank"}
                  color="#fff"
                  variant={"body2"}
                  fontWeight={500}
                  paddingBottom={{ xs: "2%", sm: "2%" }}
                  lineHeight={2}
                  sx={{
                    "&:hover": {
                      color: "#ddd",
                    },
                  }}
                >
                  Govspend
                </Link>
                <Link
                  underline="none"
                  component="a"
                  href="https://me.yourbudgit.com/login"
                  target={"_blank"}
                  color="#fff"
                  variant={"body2"}
                  fontWeight={500}
                  paddingBottom={{ xs: "2%", sm: "2%" }}
                  lineHeight={2}
                  sx={{
                    "&:hover": {
                      color: "#ddd",
                    },
                  }}
                >
                  Personalised Data
                </Link>
              </Box>
              <Box
                display="flex"
                flexWrap={"wrap"}
                flexDirection={"column"}
                marginTop={{ xs: "10%", sm: "0%" }}
                width={{ xs: "90%", sm: "30%" }}
                alignItems={"flex-start"}
              >
                <Typography
                  sx={{ textTransform: "capitalize", fontWeight: 700 }}
                  gutterBottom
                  color={"#fff"}
                  align={"left"}
                  variant={"body2"}
                  paddingBottom={{ xs: "5%", sm: "10%" }}
                >
                  Contact Us
                </Typography>
                <Box
                  display={"flex"}
                  alignItems={"flex-start"}
                  marginLeft={{ xs: "0%", sm: "0%" }}
                  paddingBottom={{ xs: "5%", sm: "5%" }}
                >
                  <PhoneIcon
                    sx={{
                      color: "#fff",
                      width: 18,
                      height: 18,
                    }}
                  />{" "}
                  <Typography
                    variant="body2"
                    color={"#fff"}
                    sx={{
                      "&:hover": {
                        color: "#ddd",
                      },
                    }}
                    textAlign={"left"}
                  >
                    (+234) 908 333 1633
                  </Typography>
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"flex-start"}
                  paddingBottom={{ xs: "5%", sm: "5%" }}
                  marginLeft={{ xs: "0%", sm: "0%" }}
                >
                  <EmailIcon
                    sx={{
                      color: "#fff",
                      width: 18,
                      height: 18,
                    }}
                  />{" "}
                  <Typography
                    variant="body2"
                    color={"#fff"}
                    sx={{
                      "&:hover": {
                        color: "#ddd",
                      },
                    }}
                    textAlign={"left"}
                  >
                    info@budgit.org
                  </Typography>
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"flex-start"}
                  justifyContent={"space-between"}
                  paddingBottom={{ xs: "5%", sm: "5%" }}
                  marginLeft={{ xs: "0%", sm: "0%" }}
                >
                  <HouseIcon
                    sx={{
                      color: "#fff",
                      width: 18,
                      height: 18,
                    }}
                  />{" "}
                  <Typography
                    variant="body2"
                    color={"#fff"}
                    sx={{
                      "&:hover": {
                        color: "#ddd",
                      },
                    }}
                    textAlign={"left"}
                  >
                    55 Moleye Street, Alagomeji, Lagos, Nigeria.
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                flexWrap={"wrap"}
                justifyContent={"space-between"}
                alignItems={"center"}
                flexDirection={{ xs: "row", sm: "column" }}
                width={{ xs: "70%", sm: "15%" }}
              >
                <Typography
                  sx={{ textTransform: "capitalize", fontWeight: 700 }}
                  gutterBottom
                  color={"#fff"}
                  align={"left"}
                  variant={"body2"}
                  display={{ xs: "none", sm: "block" }}
                >
                  Follow Us
                </Typography>
                <Box
                  display={"flex"}
                  component="a"
                  underline="none"
                  target={"_blank"}
                  href="https://twitter.com/BudgITng"
                  title="Twitter"
                >
                  <Avatar
                    sx={{
                      bgcolor: "#4f4f4f",
                      width: 36,
                      height: 36,
                      "&:hover": {
                        bgcolor: blue[300],
                      },
                    }}
                  >
                    <TwitterIcon />
                  </Avatar>
                </Box>
                <Box
                  display={"flex"}
                  component="a"
                  underline="none"
                  target={"_blank"}
                  href="https://www.facebook.com/budgitng/"
                  title="Facebook"
                >
                  <Avatar
                    sx={{
                      bgcolor: "#4f4f4f",
                      width: 36,
                      height: 36,
                      "&:hover": {
                        bgcolor: blue[900],
                      },
                    }}
                  >
                    <FacebookIcon />
                  </Avatar>
                </Box>
                <Box
                  display={"flex"}
                  component="a"
                  underline="none"
                  target={"_blank"}
                  href="https://www.linkedin.com/company/budgit-nigeria"
                  title="LinkedIn"
                >
                  <Avatar
                    sx={{
                      bgcolor: "#4f4f4f",
                      width: 36,
                      height: 36,
                      "&:hover": {
                        bgcolor: blue[700],
                      },
                    }}
                  >
                    <LinkedInIcon />
                  </Avatar>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider color={"#fff"} />
          <Box
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
            flexWrap={"wrap"}
            width={"100%"}
            marginTop={"3%"}
          >
            <Box width={{ xs: "100%", sm: "100%" }}>
              <Typography align={"center"} variant={"subtitle2"} color="#fff">
                &copy;{new Date().getFullYear()} BudgIT Foundation. All rights
                reserved
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
