import { light as lightGreen, dark as darkGreen } from "./palette--green";
import { light as lightBlue, dark as darkBlue } from "./palette--blue";

const palette = (themeMode = "light", paletteType = "#049593") => {
  if (paletteType === "blue") {
    return themeMode === "dark" ? darkBlue : lightBlue;
  }

  return themeMode === "dark" ? darkGreen : lightGreen;
};

export default palette;
