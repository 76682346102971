import React from "react";
import { Helmet } from "react-helmet-async";
import Box from "@mui/material/Box";
import Container from "../../common/Container";
import Banner from "./components/Banner";
import Details from "./components/Details";
import Image from "../../assets/Banner/banner4.jpg";

const styles = {
  paperContainer: {
    height: 350,
    backgroundImage: `url(${Image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
};

const Indicators = () => {
  return (
    <Box>
      <Helmet>
        <title>
          {" "}
          Background Indicators | States Fiscal Transparency League
        </title>
      </Helmet>
      <Box style={styles.paperContainer}>
        <Banner />
      </Box>
      <Box>
        <Container>
          <Details />
        </Container>
      </Box>
    </Box>
  );
};

export default Indicators;
