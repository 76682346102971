import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "../../../common/Container";

const Banner = () => {
  return (
    <Box marginTop={{ xs: "4%", sm: "0%" }}>
      <Container>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
        >
          <Box
            paddingBottom={"3%"}
            position={"absolute"}
            top={{ xs: "25%", sm: "30%" }}
            width={{ xs: "90%", sm: "80%" }}
          >
            <Typography
              variant="h2"
              color="#fff"
              fontWeight={700}
              paddingBottom={"2%"}
              textAlign={"left"}
            >
              Latest Ranking
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Banner;
