import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import LeagueTable from "./LeagueTable";
import { useDispatch } from "react-redux";
import { uiInfoActions } from "../../../actions";

const TransparencyPage = () => {
  const dispatch = useDispatch();
  const quarters = useSelector((state) => state.publishedQuarter.data);

  useEffect(() => {
    dispatch(uiInfoActions.publishedQuarter());
  }, []);

  if (quarters) {
    return <LeagueTable quarters={quarters} />;
  } else {
    return <></>;
  }
};

export default TransparencyPage;
