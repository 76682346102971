import { combineReducers } from "redux";
import auth from "./auth.reducer";
import budgitSnack from "./snackbar.reducer";
import { newPeriod, newLeagueScore, newStateAnalysis } from "./score.reducer";
import {
  leagueInfo,
  period,
  periodByID,
  analysisPeriodByID,
  states,
  publishedQuarter,
} from "./league.reducer";

const rootReducer = combineReducers({
  auth,
  budgitSnack,
  newPeriod,
  newLeagueScore,
  newStateAnalysis,
  leagueInfo,
  period,
  publishedQuarter,
  periodByID,
  analysisPeriodByID,
  states,
});

export default rootReducer;
