import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { clearSnackbar } from "../../actions";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function SuccessSnackbar() {
  const dispatch = useDispatch();
  const { successSnackbarMessage, successSnackbarOpen } = useSelector(
    (state) => state.budgitSnack
  );

  function handleClose() {
    dispatch(clearSnackbar());
  }

  return (
    <Snackbar
      open={successSnackbarOpen}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
        {successSnackbarMessage}
      </Alert>
    </Snackbar>
  );
}

export function ErrorSnackbar() {
  const dispatch = useDispatch();
  const { errorSnackbarMessage, errorSnackbarOpen } = useSelector(
    (state) => state.budgitSnack
  );

  function handleClose() {
    dispatch(clearSnackbar());
  }

  return (
    <Snackbar
      open={errorSnackbarOpen}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
        {errorSnackbarMessage}
      </Alert>
    </Snackbar>
  );
}

export function InfoSnackbar() {
  const dispatch = useDispatch();
  const { infoSnackbarMessage, infoSnackbarOpen } = useSelector(
    (state) => state.budgitSnack
  );

  function handleClose() {
    dispatch(clearSnackbar());
  }

  return (
    <Snackbar
      open={infoSnackbarOpen}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="info" sx={{ width: "100%" }}>
        {infoSnackbarMessage}
      </Alert>
    </Snackbar>
  );
}

export function WarningSnackbar() {
  const dispatch = useDispatch();
  const { warningSnackbarMessage, warningSnackbarOpen } = useSelector(
    (state) => state.budgitSnack
  );

  function handleClose() {
    dispatch(clearSnackbar());
  }

  return (
    <Snackbar
      open={warningSnackbarOpen}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
        {warningSnackbarMessage}
      </Alert>
    </Snackbar>
  );
}
