export const method = [
  {
    indicator: "MTEF",
    time: "June - 5, July - 3, August - 2, September - 1",
    availability:
      "Available on the website - 5, Available but not on state website - 3",
    comprehensive:
      "(includes all components) Revenue Analysis - 1, Line Items - 1",
    total: "12",
  },
  {
    indicator: "Proposed Budget",
    time: "5th - 6th week of Q1 - 4, 7th  - 8th week of Q1 - 3, 9th - 10th week of Q1 - 2, 11th - 12th week of Q1 - 1, 1st week of Q2 - 0",
    availability:
      "On the website - 3, Available but not on the website - 1, Not Available - 0",
    comprehensive:
      "Budget Summary - 1, Expenditure by MDA - 1, Total Revenue (including Capital Receipts) by Administrative Classification - 1, Capital Expenditure by Project - 2, Capital Expenditure by Programme - 1",
    total: "13",
  },
  {
    indicator: "Approved Budget",
    time: "(January 31) January - 6, February - 4, March - 2, April - 0",
    availability:
      "On the website - 5, Available but not on the website - 3, Not Available - 0",
    comprehensive:
      "Budget Summary - 2, Expenditure by MDA - 1, Total Revenue (including Capital Receipts) by Administrative Classification - 1, Total Expenditure by Administrative Classification - 1, Total Expenditure by Functional Classification - 1, Capital Expenditure by Project - 3, Capital Expenditure by Programme - 1",
    total: "21",
  },
  {
    indicator: "Citizens Budget",
    time: "(Q2 of the following fiscal year) February - 5, March - 3, April - 1, May - 0",
    availability:
      "On the website - 3, Available but not on the website - 1, Not Available - 0",
    comprehensive:
      "Budget summary - 1, Fiscal framework revenue - 1, Fiscal framework expenditure - 1, Top priority projects - 2, Top sector/ministry allocation - 2",
    total: "15",
  },
  {
    indicator: "Quarterly BIR",
    time: "(30 days after the end of each quarter) 30 days after the end of each quarter - 5, 60 days after the end of each quarter - 2, After 60 days - 0",
    availability:
      "On the website - 2, Available but not on the website - 1, Not Available - 0",
    comprehensive:
      "Summary of Performance with Revenue lines - 2, Summary of Performance with Expenditure lines - 2, Top Capital Allocations to Ministries - 3, Deficit Performance - 2",
    total: "16",
  },
  {
    indicator: "Audit Report",
    time: "(on or before August) September - 3, October - 2, November - 1",
    availability:
      "On the website - 2, Available but not on the website - 1, Not Available - 0",
    comprehensive:
      "Notes on Infractions - 2, Financial Notes - 2, Balance Sheet and Income Statement - 3, Auditor’s name, signature and certificate - 1, Recommendations - 2",
    total: "15",
  },
  {
    indicator: "Accountant General's report /Financial Statement",
    time: "(the audited financial statements for 2020 must be published by September 2021) - 5",
    availability:
      "On the website - 5, Available but not on the website - 3, Not Available - 0",
    comprehensive:
      "Auditor Certificate - 1, Cash flow statement - 1, Statement of assets and liabilities - 1, Statement of consolidated revenue fund - 1, Statement of capital development fund - 1, Statement of responsibility - 1, Consolidated financial summary - 1, Comments of the State Auditor General / Responsibilities for financial statements - 1",
    total: "18",
  },
  {
    indicator: "e-Procurement Portal",
    time: "",
    availability: "Accessibility, Navigation - 3",
    comprehensive:
      "Website with updated data - 4, Beneficial Ownership - 2, Contracting entities (company name) - 2",
    total: "11",
  },
  {
    indicator: "States' Functional Website /Fiscal Repository",
    time: "",
    availability:
      "Compartmentability of the Document - 3, Fiscal Documents - 3, User Experience - 3, Navigation - 3",
    comprehensive: "",
    total: "12",
  },
];
