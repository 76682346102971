import React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { method } from "../../../static/method";

const Details = () => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#1C4E63",
      color: theme.palette.common.white,
      fontWeight: 700,
      paddingLeft: "3%",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      paddingLeft: "3%",
    },
  }));

  const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      backgroundColor: "#1C4E63",
      color: theme.palette.common.white,
      fontSize: 14,
      padding: "3%",
      align: "left",
    },
  }));
  const StyledTableCell3 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      backgroundColor: "#1C4E63",
      color: theme.palette.common.white,
      fontSize: 14,
      padding: "3% 10% 3% 3%",
      textAlign: "left",
    },
  }));

  const StyledTableCell4 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      backgroundColor: "#1C4E63",
      color: theme.palette.common.white,
      fontSize: 18,
      padding: "3% 1% 3% 2%",
      textAlign: "left",
      fontWeight: 700,
    },
  }));

  return (
    <Grid container margin={{ xs: "15% 0%", sm: "5% 0%" }}>
      <Grid item xs={12} sm={12}>
        <Typography
          variant="h3"
          color={"#049593"}
          align={"left"}
          fontWeight={700}
          lineHeight={1.5}
          gutterBottom
        >
          League Scoring Methodology
        </Typography>
      </Grid>
      <Box margin={{ xs: "10% 0%", sm: "2% 0%" }}>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontWeight: 700,
                    fontSize: 14,
                    backgroundColor: "#1C4E63",
                    color: "#fff",
                  }}
                >
                  S/N
                </TableCell>
                <StyledTableCell align="left">Indicator</StyledTableCell>
                <StyledTableCell align="left">Timeliness</StyledTableCell>
                <StyledTableCell align="left">Availability</StyledTableCell>
                <StyledTableCell align="left">
                  Comprehensiveness
                </StyledTableCell>
                <TableCell
                  style={{
                    fontWeight: 700,
                    fontSize: 14,
                    backgroundColor: "#1C4E63",
                    color: "#fff",
                  }}
                >
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {method.map((row, index) => (
                <TableRow key={index}>
                  <StyledTableCell2 align="left">{index + 1}</StyledTableCell2>
                  <StyledTableCell2 align="left">
                    {row.indicator}
                  </StyledTableCell2>
                  <StyledTableCell2 align="left">{row.time}</StyledTableCell2>
                  <StyledTableCell2 align="left">
                    {row.availability}
                  </StyledTableCell2>
                  <StyledTableCell3 align="left">
                    {row.comprehensive}
                  </StyledTableCell3>
                  <StyledTableCell4 align="left">{row.total}</StyledTableCell4>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Grid>
  );
};

export default Details;
