import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { indicators } from "../../../static/indicators";

const Details = () => {
  return (
    <Grid container margin={{ xs: "15% 0%", sm: "5% 0% 2% 0%" }}>
      <Grid item xs={12} sm={12}>
        <Typography
          variant="h3"
          color={"#049593"}
          align={"left"}
          fontWeight={700}
          gutterBottom
          paddingBottom={{ xs: "5%", sm: "2%" }}
        >
          Background Indicators
        </Typography>
        <Typography
          variant="body1"
          color={"#4f4f4f"}
          align={"left"}
          paddingBottom={{ xs: "8%", sm: "2%" }}
          gutterBottom
        >
          Below are the background indicators that will be used for the Fiscal
          Transparency League Table Index:
        </Typography>
      </Grid>
      {indicators.map((detail, index) => (
        <Grid
          item
          xs={12}
          sm={12}
          key={index}
          paddingBottom={{ xs: "15%", sm: "5%" }}
        >
          <Typography
            variant="h5"
            color={"#049593"}
            fontWeight={700}
            gutterBottom
          >
            <Typography
              component={"span"}
              color="#D7A036"
              fontWeight={800}
              variant={"h4"}
            >
              {detail.no} {""}
            </Typography>
            {detail.title}
          </Typography>
          <Typography variant="body1" color={"#4f4f4f"} lineHeight={1.5}>
            {detail.description}
          </Typography>
          <Typography
            variant="body2"
            color={"#1C4E63"}
            lineHeight={1.5}
            fontWeight={700}
            fontS
            paddingTop={"1%"}
          >
            {detail.highlight}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default Details;
