const navConfig = [
  {
    title: "Latest Ranking",
    path: "/latest-ranking",
  },
  {
    title: "Appraisals",
    path: "/state-by-state-appraisals",
  },
  {
    title: "Overview",
    path: "/overview",
  },
  {
    title: "Indicators",
    path: "/background-indicators",
  },
  {
    title: "Methodology",
    path: "/league-scoring-methodology",
  },
];

export default navConfig;
