import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ListQuarter from "./ListQuarter";
import { useDispatch } from "react-redux";
import { uiInfoActions } from "../../../actions";

const PublishQuarter = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const quarters = useSelector((state) => state.period.data);

  useEffect(() => {
    dispatch(uiInfoActions.period());
  }, []);

  if (quarters) {
    return <ListQuarter quarters={quarters} token={token} />;
  } else {
    return <></>;
  }
};

export default PublishQuarter;
