export const scoreConstants = {
  LEAGUE_REQUEST: "LEAGUE_REQUEST",
  PERIOD_REQUEST: "PERIOD_REQUEST",
  ANALYSIS_REQUEST: "ANALYSIS_REQUEST",

  LEAGUE_SUCCESS: "LEAGUE_SUCCESS",
  PERIOD_SUCCESS: "PERIOD_SUCCESS",
  ANALYSIS_SUCCESS: "ANALYSIS_SUCCESS",

  LEAGUE_FAILURE: "LEAGUE_FAILURE",
  PERIOD_FAILURE: "PERIOD_FAILURE",
  ANALYSIS_FAILURE: "ANALYSIS_FAILURE",
};
