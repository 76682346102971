import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import * as _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { scoreActions } from "../../../actions";
import Container from "../../../common/Container";

const validationSchema = yup.object({
  quarter: yup
    .string("Enter the New Quarter")
    .trim()
    .required("Enter the New Quarter"),
  publish: yup
    .string("Select the Published Option")
    .required("Select the Published Option"),
});

const ColorButton = styled(Button)(() => ({
  color: "#fff",
  width: 150,
  height: 55,
  fontWeight: 600,
  fontSize: "18px",
  textTransform: "capitalize",
  backgroundColor: "#049593",
  borderColor: "#049593",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#1C4E63",
    borderColor: "#1C4E63",
  },
}));

const AddPeriod = () => {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  const initialValues = {
    quarter: "",
    publish: false,
  };

  const onSubmit = (values) => {
    return values;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const handleSubmit = (e) => {
    formik.handleSubmit();
    e.preventDefault();

    const addNewPeriod = {
      quarter: formik.values.quarter,
      publish: formik.values.publish,
    };
    if (_.isEmpty(formik.errors) && !_.isEmpty(addNewPeriod.quarter)) {
      dispatch(scoreActions.newPeriod(token, addNewPeriod));
    }
  };

  return (
    <Box
      marginTop={{ xs: "1%", sm: "2%" }}
      marginBottom={{ xs: "1%", sm: "16%" }}
    >
      <Box display={"flex"} alignItems={"center"} height={"100%"}>
        <Container maxWidth={700}>
          <Box>
            <Box marginBottom={4} marginTop={{ xs: "2%", md: "0%" }}>
              <Typography
                variant="h5"
                color={"#049593"}
                align={"left"}
                fontWeight={700}
                gutterBottom
                marginBottom={{ xs: "15%", sm: "2%" }}
              >
                Add New Period
              </Typography>
            </Box>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
                    Quarter
                  </Typography>
                  <TextField
                    variant="outlined"
                    name={"quarter"}
                    placeholder="Q1 2028"
                    fullWidth
                    required
                    value={formik.values.quarter}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.quarter && Boolean(formik.errors.quarter)
                    }
                    helperText={formik.touched.quarter && formik.errors.quarter}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
                    Publish
                  </Typography>
                  <FormControl required fullWidth>
                    <Select
                      labelId="publish"
                      id="publish"
                      name={"publish"}
                      value={formik.values.publish}
                      onChange={formik.handleChange}
                      fullWidth
                      error={
                        formik.touched.publish && Boolean(formik.errors.publish)
                      }
                      helperText={
                        formik.touched.publish && formik.errors.publish
                      }
                    >
                      <MenuItem value={true}>Published</MenuItem>
                      <MenuItem value={false}>Not Published</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Box
                    display="flex"
                    flexDirection={{ xs: "column", sm: "row" }}
                    alignItems={{ xs: "center", sm: "center" }}
                    justifyContent={"space-between"}
                    width={"100%"}
                    marginLeft={{ xs: "0%", sm: "0%" }}
                  >
                    <ColorButton
                      size={"large"}
                      variant={"contained"}
                      type={"submit"}
                    >
                      Submit
                    </ColorButton>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default AddPeriod;
