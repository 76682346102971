import React from "react";
import {
  ErrorSnackbar,
  InfoSnackbar,
  SuccessSnackbar,
  WarningSnackbar,
} from "./Snackbars";

export default function BudgITSnackbar() {
  return (
    <>
      <SuccessSnackbar />
      <InfoSnackbar />
      <WarningSnackbar />
      <ErrorSnackbar />
    </>
  );
}
