import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "../../../../common/Container";

const Footer = () => {
  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
            flexWrap={"wrap"}
            width={"100%"}
          >
            <Box width={{ xs: "100%", sm: "100%" }}>
              <Typography
                align={"center"}
                variant={"subtitle2"}
                color="#049593"
              >
                &copy;{new Date().getFullYear()} BudgIT Foundation. All rights
                reserved
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
