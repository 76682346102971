import { postRequest, editRequest } from "./common";

export const scoreService = {
  addPeriod,
  addLeagueScore,
  addAnalysis,
  editLeagueData,
  editStateAnalysis,
};

function addLeagueScore(token, score) {
  return postRequest(score, token, "league-data");
}
function addAnalysis(token, newAnalysis) {
  return postRequest(newAnalysis, token, "state-analysis");
}

function addPeriod(token, newQuarter) {
  return postRequest(newQuarter, token, "period");
}
function editLeagueData(token, data, id) {
  return editRequest(data, token, `league-data/${id}`);
}
function editStateAnalysis(token, data, id) {
  return editRequest(data, token, `/state-analysis/${id}`);
}
