import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Form, Formik, FastField } from "formik";
// import Container from "../../../common/Container";
import { useDispatch } from "react-redux";
import { scoreActions } from "../../../actions";
import * as _ from "lodash";
import * as yup from "yup";

const validationSchema = yup.object({
  state_analysis: yup.string().required(),
});

const StateAnalysisForm = ({ quarters, states, token }) => {
  const loading = "";

  const dispatch = useDispatch();

  const [selectedPeriod, setSelectedPeriod] = useState(quarters.period_id);

  const initialValues = {
    states: states.reduce(
      (acc, { state_id, state_name }) => ({
        ...acc,
        [state_id]: {
          id: state_id,
          name: state_name,
          state_analysis: "",
        },
      }),
      {}
    ),
  };

  const handleChangePeriod = (event) => {
    setSelectedPeriod(event.target.value);
  };

  const ColorButton = styled(Button)(() => ({
    color: "#fff",
    width: 140,
    height: 40,
    fontSize: "14px",
    textTransform: "capitalize",
    backgroundColor: "#049593",
    borderColor: "#049593",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#1C4E63",
      borderColor: "#1C4E63",
    },
  }));

  const handleSubmit = (values) => {
    const addStateAnalysis = Object.values(values.states).map((state) => {
      return {
        state_analysis: state.state_analysis,
        period_id: selectedPeriod,
        state_id: state.id,
      };
    });

    if (!_.isEmpty(addStateAnalysis)) {
      dispatch(scoreActions.newStateAnalysis(token, addStateAnalysis));
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#1C4E63",
      color: theme.palette.common.white,
      fontWeight: 700,
      minWidth: 50,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      minWidth: 170,
    },
  }));

  const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#1C4E63",
      color: theme.palette.common.white,
      fontWeight: 700,
      minWidth: 600,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <div>
      <Box>
        <Typography
          variant="h3"
          color={"#049593"}
          align={"left"}
          fontWeight={700}
          gutterBottom
          marginBottom={{ xs: "15%", sm: "2%" }}
        >
          Add State Analysis
        </Typography>
        <Typography
          variant={"subtitle2"}
          sx={{ marginBottom: 1 }}
          fontWeight={700}
          color={"#1C4E63"}
        >
          Select Quarter
        </Typography>
        <Formik
          initialValues={initialValues}
          // validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Form>
              <Box
                marginBottom={"2%"}
                width={"25%"}
                marginLeft={{ xs: "0%", sm: "0%" }}
              >
                <FormControl required fullWidth>
                  <Select
                    labelId="quarter"
                    id="quarter"
                    name={"quarter"}
                    placeholder="Select Quarter"
                    value={selectedPeriod}
                    onChange={handleChangePeriod}
                    fullWidth
                  >
                    {quarters.map((periodId) => (
                      <MenuItem key={periodId} value={periodId.period_id}>
                        {periodId.quarter}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <TableContainer component={Paper}>
                <Table marginTop={"2%"}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">States</StyledTableCell>
                      <StyledTableCell2 align="left">
                        State Analysis
                      </StyledTableCell2>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.values(props.values.states).map((state) => (
                      <StyledTableRow key={state.id}>
                        <TableCell>
                          <FormControl fullWidth required>
                            <Select
                              labelId={`state-${state.id}`}
                              id={`state-${state.id}`}
                              name={`state-${state.id}`}
                              value={state.id}
                              inputProps={{ readOnly: true }}
                              fullWidth
                            >
                              <MenuItem value={state.id}>{state.name}</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <FastField
                            name={`states.${state.id}.state_analysis`}
                            as={TextField}
                            type="string"
                            multiline
                            rows={4}
                            fullWidth
                          />
                        </TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
                <Box
                  margin={"2% 0%"}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <ColorButton
                    type={"submit"}
                    loading={loading}
                    variant="outlined"
                    size="medium"
                  >
                    Submit
                  </ColorButton>
                </Box>
              </TableContainer>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

export default StateAnalysisForm;
