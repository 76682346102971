import { React, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, List, ListItemText } from "@mui/material";
import { StyledNavItem, StyledNavItemIcon } from "./styles";
import { withStyles } from "@mui/styles";
import MuiListItem from "@mui/material/ListItem";

NavSection.propTypes = {
  data: PropTypes.array,
};

const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#049593",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
    "&$selected:hover": {
      backgroundColor: "#40E0D0",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "#white",
      },
    },
    "&:hover": {
      backgroundColor: "white",
      color: "#049593",
      "& .MuiListItemIcon-root": {
        color: "#049593",
      },
    },
    alignItems: "left",
  },
  selected: {},
})(MuiListItem);

export default function NavSection({ data = [], ...other }) {
  const [activeLink, setActiveLink] = useState("");
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : "");
  }, []);

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <ListItem selected={item.path === activeLink}>
            <NavItem key={item.title} item={item} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem component={"a"} to={path}>
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
