import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import Logout from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import PostAddIcon from "@mui/icons-material/PostAdd";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import QueueIcon from "@mui/icons-material/Queue";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Link } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import logo from "../../../../assets/logo/logo.png";

const drawerWidth = 200;

const Topbar = ({ name }) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const [open, setOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const show = Boolean(anchorEl);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(!open);
  };

  const menuOptions = [
    {
      label: "Dashboard",
      icon: <HomeIcon />,
      selected: selectedIndex === 0,
      onClick: (event) => handleListItemClick(event, 0),
      link: "/dashboard/app",
    },
    {
      label: "League Table Form",
      icon: <PostAddIcon />,
      selected: selectedIndex === 1,
      onClick: (event) => handleListItemClick(event, 1),
      link: "/dashboard/add-league-score",
    },
    {
      label: "State Analysis Form",
      icon: <QueueIcon />,
      selected: selectedIndex === 2,
      onClick: (event) => handleListItemClick(event, 2),
      link: "/dashboard/add-state-analysis",
    },
    {
      label: "Publish Quarter",
      icon: <MoreTimeIcon />,
      selected: selectedIndex === 3,
      onClick: (event) => handleListItemClick(event, 3),
      link: "/dashboard/publish-quarter",
    },
    {
      label: "Add Quarter",
      icon: <WatchLaterIcon />,
      selected: selectedIndex === 4,
      onClick: (event) => handleListItemClick(event, 4),
      link: "/dashboard/add-quarter",
    },
    {
      label: "Add User",
      icon: <GroupAddIcon />,
      selected: selectedIndex === 5,
      onClick: (event) => handleListItemClick(event, 5),
      link: "/dashboard/add-user",
    },
    {
      label: "Logout",
      icon: <Logout />,
      onclick: () => {
        localStorage.removeItem("token");
        window.location.href = "/login";
      },
    },
  ];

  const logOut = () => {
    localStorage.removeItem("token");
    window.location.href = "/login";
  };

  return (
    <>
      <Box
        display={{ xs: "none", md: "flex" }}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
        margin={2}
      >
        {/* <Box display={"flex"} alignItems={"center"}>
          <Box
            component="a"
            underline="none"
            href="/dashboard/app"
            title="BudgIT"
            height={{ xs: 28, md: 32 }}
            width={130}
          >
            <img src={logo} alt="logo" width={"100%"} />
          </Box>
        </Box> */}
        <Box
          sx={{ display: { xs: "none", md: "flex" } }}
          alignItems={"center"}
          justifyContent={"flex-end"}
          marginRight={{ xs: "0%", md: "5%" }}
        >
          <Box>
            <IconButton
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleClick}
              id="account-menu"
              aria-controls={show ? "account-menu" : undefined}
              aria-expanded={show ? "true" : undefined}
              aria-haspopup="true"
              color="primary"
            >
              {/* <Avatar {...stringAvatar()} /> */}
              <MoreVertIcon fontSize="large" />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={show}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                    py: 1,
                    px: 2.5,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zindex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <Divider />
              <MenuItem onClick={logOut}>Logout</MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: { xs: "inline", md: "none" } }}>
        <AppBar
          position="fixed"
          zindex={theme.zIndex.drawer + 1}
          sx={{
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Toolbar>
            <Box
              component="a"
              underline="none"
              href="/dashboard/app"
              title="BudgIT"
              height={{ xs: 28, md: 32 }}
              width={85}
              marginRight={26}
            >
              <img src={logo} alt="logo" width={"100%"} />
            </Box>
            <IconButton
              color="primary"
              aria-label="open drawer"
              edge="end"
              onClick={toggleDrawer}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          flexshrink={0}
          width={drawerWidth}
          variant={isMdUp ? "permanent" : "temporary"}
          classes={{
            paper: { width: drawerWidth },
          }}
          anchor="left"
          open={open}
          onClose={toggleDrawer}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            borderRadius={"5px"}
            backgroundColor={theme.palette.background.level2}
            padding={1}
          >
            <Avatar />
            <Box sx={{ ml: 2 }}>
              <Typography
                variant="subtitle2"
                fontWeight={700}
                sx={{ color: "primary" }}
              >
                {name}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <List component="nav">
            {menuOptions.map((option, index) => {
              const { label, onclick, link, selected } = option;
              return (
                <ListItem
                  button
                  key={index}
                  onClick={onclick}
                  selected={selected}
                  style={{ textAlign: "left" }}
                >
                  <Link
                    href={link}
                    variant="inherit"
                    color="textPrimary"
                    underline="none"
                  >
                    <ListItemText primary={label} />
                  </Link>
                </ListItem>
              );
            })}
          </List>
        </Drawer>
      </Box>
    </>
  );
};

Topbar.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Topbar;
