import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const alertActions = {
  success,
  error
};

const MySwal = withReactContent(Swal);

function success(message) {
  MySwal.fire({
    title: 'Success!',
    text: message,
    icon: 'success'
  });
}

function error(message) {
  MySwal.fire({
    title: 'Oops!',
    text: message,
    icon: 'error',
  });
}