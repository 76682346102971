import { scoreService } from "../services";
import { alertActions, userActions } from ".";
import { scoreConstants } from "../constants";
import { history } from "../helpers";
import { showSuccessSnackbar } from "./";

export const scoreActions = {
  // updateScores,
  newLeagueScore,
  newStateAnalysis,
  newPeriod,
  editScores,
  editStateAnalysis,
};

// function updateScores(data) {
//   return (dispatch) => {
//     dispatch(success(data));
//   };

//   function success(data) {
//     return { type: scoreConstants.LEAGUE_SUCCESS, data };
//   }
// }

function newLeagueScore(token, score) {
  return (dispatch) => {
    dispatch(request());
    return scoreService.addLeagueScore(token, score).then(
      (res) => {
        dispatch(success());
        dispatch(showSuccessSnackbar("League Data Submitted Successfully!"));
        history.push("/dashboard/add-league-score");
        history.go("/dashboard/add-league-score");
      },
      (error) => {
        dispatch(failure(error));
        if (error === 401) {
          userActions.expiredSession();
        } else {
          alertActions.error(error.toString());
        }
      }
    );
  };

  function request() {
    return { type: scoreConstants.LEAGUE_REQUEST };
  }
  function success() {
    return { type: scoreConstants.LEAGUE_SUCCESS };
  }
  function failure(error) {
    return { type: scoreConstants.LEAGUE_FAILURE, error };
  }
}

function newStateAnalysis(token, newAnalysis) {
  return (dispatch) => {
    dispatch(request());
    return scoreService.addAnalysis(token, newAnalysis).then(
      (res) => {
        dispatch(success());
        dispatch(showSuccessSnackbar("New Analysis Submitted Successfully!"));
        history.push("/dashboard/add-state-analysis");
        history.go("/dashboard/add-state-analysis");
      },
      (error) => {
        dispatch(failure(error));
        if (error === 401) {
          userActions.expiredSession();
        } else {
          alertActions.error(error.toString());
        }
      }
    );
  };

  function request() {
    return { type: scoreConstants.ANALYSIS_REQUEST };
  }
  function success() {
    return { type: scoreConstants.ANALYSIS_SUCCESS };
  }
  function failure(error) {
    return { type: scoreConstants.ANALYSIS_FAILURE, error };
  }
}

function editStateAnalysis(token, updateAnalysis, id) {
  return (dispatch) => {
    dispatch(request());
    return scoreService.editStateAnalysis(token, updateAnalysis, id).then(
      (res) => {
        dispatch(success());
        dispatch(showSuccessSnackbar("Analysis Updated Successfully!"));
        history.push("/dashboard/edit-state-analysis");
        history.go("/dashboard/edit-state-analysis");
      },
      (error) => {
        dispatch(failure(error));
        if (error === 401) {
          userActions.expiredSession();
        } else {
          alertActions.error(error.toString());
        }
      }
    );
  };

  function request() {
    return { type: scoreConstants.ANALYSIS_REQUEST };
  }
  function success() {
    return { type: scoreConstants.ANALYSIS_SUCCESS };
  }
  function failure(error) {
    return { type: scoreConstants.ANALYSIS_FAILURE, error };
  }
}

function newPeriod(token, newQuarter) {
  return (dispatch) => {
    dispatch(request());
    return scoreService.addPeriod(token, newQuarter).then(
      (res) => {
        dispatch(success());
        dispatch(showSuccessSnackbar("New Quarter Added Successfully!"));
        history.push("/dashboard/add-quarter");
        history.go("/dashboard/add-quarter");
      },
      (error) => {
        dispatch(failure(error));
        if (error === 401) {
          userActions.expiredSession();
        } else {
          alertActions.error(error.toString());
        }
      }
    );
  };

  function request() {
    return { type: scoreConstants.PERIOD_REQUEST };
  }
  function success() {
    return { type: scoreConstants.PERIOD_SUCCESS };
  }
  function failure(error) {
    return { type: scoreConstants.PERIOD_FAILURE, error };
  }
}

function editScores(token, data, id) {
  return (dispatch) => {
    dispatch(request());
    return scoreService.editLeagueData(token, data, id).then(
      (res) => {
        dispatch(success());
        dispatch(showSuccessSnackbar("League Scores Updated Successfully!"));
        history.push("/dashboard/edit-league-score");
        history.go("/dashboard/edit-league-score");
      },
      (error) => {
        dispatch(failure(error));
        if (error === 401) {
          userActions.expiredSession();
        } else {
          alertActions.error(error.toString());
        }
      }
    );
  };

  function request() {
    return { type: scoreConstants.LEAGUE_REQUEST };
  }
  function success() {
    return { type: scoreConstants.LEAGUE_SUCCESS };
  }
  function failure(error) {
    return { type: scoreConstants.LEAGUE_FAILURE, error };
  }
}
