import { userConstants } from "../constants";
import { userService } from "../services";
import { alertActions } from "./";
import { history } from "../helpers";
import { showSuccessSnackbar } from "./";

export const userActions = {
  login,
  logout,
  reset,
  register,
  session,
  expiredSession,
};

function login({ email, password }) {
  return (dispatch) => {
    dispatch(request({ email }));
    userService.login(email, password).then(
      (res) => {
        localStorage.setItem("budgit_user", JSON.stringify(email));
        localStorage.setItem("budgit_token", JSON.stringify(res.token));
        dispatch(success(email, res.token));
        dispatch(showSuccessSnackbar("Login Successful"));

        history.push("/dashboard/app");
        history.go("/dashboard/app");
      },
      (error) => {
        dispatch(failure(error.toString()));
        alertActions.error(error.toString());
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user, token) {
    return { type: userConstants.LOGIN_SUCCESS, user, token };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  localStorage.removeItem("budgit_user");
  localStorage.removeItem("budgit_token");
  history.push("/login");
  return { type: userConstants.LOGOUT };
}

function reset() {
  localStorage.removeItem("budgit_user");
  localStorage.removeItem("budgit_token");
  return { type: userConstants.LOGOUT };
}

function session(token) {
  return (dispatch) => {
    dispatch(request());
    return userService.session(token).then(
      (res) => {
        dispatch(success(res.token, res.user));
        localStorage.setItem("budgit_token", JSON.stringify(res.token));
        localStorage.setItem("budgit_user", JSON.stringify(res.user));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.SESSION_REQUEST };
  }
  function success(token, user) {
    return { type: userConstants.SESSION_SUCCESS, token, user };
  }
  function failure(error) {
    return { type: userConstants.SESSION_FAILURE, error };
  }
}

function register(newUser) {
  return (dispatch) => {
    dispatch(request(newUser));

    userService.register(newUser).then(
      (res) => {
        dispatch(success());
        dispatch(showSuccessSnackbar("User Added Successfully"));
        history.push("/dashboard/add-user");
        history.go("/dashboard/add-user");
      },
      (error) => {
        dispatch(failure(error.toString()));
        alertActions.error(error.toString());
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function expiredSession() {
  // add session expired alert
  logout();
}
