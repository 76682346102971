import * as config from "../config";
import axios from "axios";

export const userService = {
  login,
  logout,
  register,
  session,
};

function login(email, password) {
  return axios({
    method: "post",
    url: `${config.BASE_URL}/api/auth/login`,
    data: {
      email: email,
      password: password,
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response) {
        return Promise.reject(err.response.data.error);
      }

      return Promise.reject(err);
    });
}

function logout() {
  // remove user from local storage to log user out
  // localStorage.removeItem('user');
}

function register(newUser) {
  return axios({
    method: "post",
    url: `${config.BASE_URL}/api/auth/register`,
    data: {
      email: newUser.email,
      password: newUser.password,
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response) {
        return Promise.reject(err.response.data.error);
      }

      return Promise.reject(err);
    });
}

function session(token) {
  return axios({
    method: "get",
    url: `${config.BASE_URL}/api/user/session`,
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response) {
        return Promise.reject(err.response.data.error);
      }

      return Promise.reject(err);
    });
}
