export const indicators = [
  {
    no: "01",
    title: "Medium-Term Expenditure Framework (MTEF)",
    description:
      "The MTEF is annual three-year-expenditure planning. It sets out the medium-term expenditure priorities and hard budget constraints against which sector plans can be developed and refined. MTEF also contains outcome criteria for the purpose of performance monitoring. MTEF together with the annual Budget Framework Paper provides the basis for annual budget planning.",
    highlight:
      "The MTEF is expected to be published on the state’s website before the end of Q3.",
  },
  {
    no: "02",
    title: "Proposed Budget",
    description:
      "This is the proposed capital and operating budget for the state, submitted to the State House of Assembly for approval.",
    highlight:
      "State governments are expected to publish this on their various websites in the fifth week of the fourth quarter (Q4) of the preceding year, to enable citizens' accessibility.",
  },
  {
    no: "03",
    title: "Approved Budget",
    description:
      "The approved budget runs from January-December which is a financial year calendar.",
    highlight:
      "This should be published to the website latest by December (Q4) of every preceding fiscal year so Citizens can have access to these documents in Q1 of the following year.",
  },
  {
    no: "04",
    title: "Citizens’ Budget",
    description:
      "This is an abridged version of the overall budget which should be in a simplified form but should have important information on where the money is coming from (revenue) and where the money is going (expenditure). Usually, this document could be in a data-visualized format which helps citizens to understand the projected spending plan for that year. Like the approved budget indicator, the citizens’ budget.",
    highlight:
      "This should be accessible on the state’s website in Q2 of the following fiscal year.",
  },
  {
    no: "05",
    title: "Budget Implementation Reports (BIR)",
    description:
      "According to Fiscal Responsibility Act, budget implementation reports are to be published 30 days after the end of each quarter.",
    highlight:
      "This is a quarterly release and it runs from Q1 - Q4 of every year.",
  },
  {
    no: "06",
    title: "Audit Report",
    description:
      "States are to publish their audited accounts not later than six months following the end of the financial year.  The document should be accessible on the state’s website on or before August when the financial report is prepared.",
    highlight: "",
  },
  {
    no: "07",
    title: "Accountant General’s Report/Financial Statement",
    description:
      "A system of internal controls must be established and maintained by the Accountant General in order to fulfill the accounting and reporting responsibilities. These controls are designed to ensure reasonable assurances that the transactions recorded are within Statutory Authority and that the Government uses all public financial resources appropriately.",
    highlight:
      "The audited financial statements for 2020 must be published by September 2021.",
  },
  {
    no: "08",
    title: "eProcurement portal",
    description:
      "This indicator looks at the establishment of an e-procurement portal for states which encourages transparency in the procurement process. In the activities for the DLI 6, by 2021, states ought to have implemented e-procurement in at least 4 MDAs (incl. Education, Health and Public Works) and publish all contract award information in OCDS format on the online portal for the 4 MDAs. For those MDAs without e-procurement, they should publish contract award information above a threshold set out in the State’s procurement law/regulation on a monthly basis in OCDS format on the state website or online portal if available. ",
    highlight: "",
  },
  {
    no: "09",
    title: "States Website with Fiscal Repository",
    description:
      "The purpose of a state's website is to serve as an official online platform for the government of a particular state. These websites aim to provide information, services, and resources to the residents, businesses, and visitors of the state. A fiscal repository is to ensure transparency and accuracy in financial matters. It allows government officials to access and retrieve financial information when needed. This helps in monitoring and evaluating the financial health of the government, making informed decisions, and ensuring proper financial management.",
    highlight: "",
  },
];
