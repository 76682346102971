import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import LeagueForm from "./LeagueForm";
import { useDispatch } from "react-redux";
import { uiInfoActions } from "../../../actions";

const LeagueTableForm = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const quarters = useSelector((state) => state.publishedQuarter.data);
  const states = useSelector((state) => state.states.data);

  useEffect(() => {
    dispatch(uiInfoActions.publishedQuarter());
    dispatch(uiInfoActions.state());
  }, []);

  if (states && quarters) {
    return <LeagueForm quarters={quarters} states={states} token={token} />;
  } else {
    return <></>;
  }
};

export default LeagueTableForm;
