import React from "react";
import "./App.css";
import { HelmetProvider } from "react-helmet-async";
import { Router } from "react-router-dom";
import { history } from "./helpers";
import Routes from "./Routes";
import BudgITSnackbar from "./common/Snackbars/BudgITSnackbar";

const App = () => {
  return (
    <HelmetProvider>
      <BudgITSnackbar />
      <Router history={history}>
        <Routes />
      </Router>
    </HelmetProvider>
  );
};

export default App;
