import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Container from "../../../common/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import * as yup from "yup";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../actions";
import * as _ from "lodash";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
  password: yup
    .string()
    .min(7, "The password should have at minimum length of 7")
    .required("Please specify your password"),
});

const ColorButton = styled(LoadingButton)(() => ({
  color: "#fff",
  width: 150,
  height: 55,
  fontWeight: 600,
  fontSize: "18px",
  textTransform: "capitalize",
  backgroundColor: "#049593",
  borderColor: "#049593",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#1C4E63",
    borderColor: "#1C4E63",
  },
}));

const AddUser = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const loading = useSelector((state) => state.auth.creating);
  const dispatch = useDispatch();

  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = (values) => {
    return values;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const handleSubmit = (e) => {
    formik.handleSubmit();
    e.preventDefault();

    const newUser = {
      email: formik.values.email.trim(),
      password: formik.values.password.trim(),
    };

    if (_.isEmpty(formik.errors) && !_.isEmpty(newUser.email)) {
      dispatch(userActions.register(newUser));
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(showPassword ? false : true);
  };

  return (
    <Box
      minHeight={{ xs: "85vh", sm: "70vh" }}
      marginLeft={{ xs: "0%", sm: "0%" }}
    >
      <Box>
        <Box display={"flex"} alignItems={"center"} height={"100%"}>
          <Container maxWidth={600}>
            <Box>
              <Box marginBottom={4} marginTop={{ xs: "5%", md: "0%" }}>
                <Typography
                  variant="h4"
                  color={"#049593"}
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  Add New User
                </Typography>
              </Box>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
                      Email Address
                    </Typography>
                    <TextField
                      variant="outlined"
                      placeholder="Enter your email"
                      name={"email"}
                      fullWidth
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
                      Password
                    </Typography>
                    <FormControl
                      sx={{ width: "100%" }}
                      variant="outlined"
                      placeholder="Enter your password"
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                    >
                      <OutlinedInput
                        name={"password"}
                        type={showPassword ? "text" : "password"}
                        fullWidth
                        placeholder="Enter your password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <FormHelperText>
                        {formik.touched.password && formik.errors.password}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box
                      display="flex"
                      flexDirection={{ xs: "column", sm: "row" }}
                      alignItems={{ xs: "center", sm: "center" }}
                      justifyContent={"space-between"}
                      width={"100%"}
                      marginLeft={{ xs: "0%", sm: "0%" }}
                    >
                      <ColorButton
                        size={"large"}
                        variant={"contained"}
                        loading={loading}
                        type={"submit"}
                      >
                        Submit
                      </ColorButton>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

AddUser.propTypes = {
  themeMode: PropTypes.string.isRequired,
};

export default AddUser;
