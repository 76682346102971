import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Container from "../../common/Container";
import { Topbar, Footer, Sidebar } from "./components";
import PostAddIcon from "@mui/icons-material/PostAdd";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import HomeIcon from "@mui/icons-material/Home";
import QueueIcon from "@mui/icons-material/Queue";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

const pages = [
  {
    title: "Dashboard",
    path: "/dashboard/app",
    icon: <HomeIcon fontSize="medium" />,
  },
  {
    title: "Add League Scores",
    path: "/dashboard/add-league-score",
    icon: <PostAddIcon fontSize="medium" />,
  },
  {
    title: "Add State Analysis",
    path: "/dashboard/add-state-analysis",
    icon: <QueueIcon fontSize="medium" />,
  },
  {
    title: "Publish Quarter",
    path: "/dashboard/publish-quarter",
    icon: <MoreTimeIcon fontSize="medium" />,
  },
  {
    title: "Add Quarter",
    path: "/dashboard/add-quarter",
    icon: <WatchLaterIcon fontSize="medium" />,
  },
  {
    title: "Add User",
    path: "/dashboard/add-user",
    icon: <GroupAddIcon fontSize="medium" />,
  },
];

const DashboardLayout = ({
  children,
  themeToggler,
  themeMode,
  setThemePalette,
  paletteType,
}) => {
  const theme = useTheme();
  const [setOpenSidebar] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const name = user ? user.email : "Admin";

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <Box height="100%" overflow="hidden" width="100%">
        <AppBar
          position={"fixed"}
          sx={{
            backgroundColor: theme.palette.background.paper,
          }}
          elevation={0}
        >
          <Container paddingY={{ xs: 1 / 2, sm: 1 }} maxWidth={{ md: "100%" }}>
            <Topbar
              name={name}
              onSidebarOpen={handleSidebarOpen}
              themeMode={themeMode}
              themeToggler={themeToggler}
              setThemePalette={setThemePalette}
              paletteType={paletteType}
            />
          </Container>
          <Divider />
        </AppBar>
        <main>
          <Box
            display={"flex"}
            justifyContent={"flex-start"}
            width={"100%"}
            marginTop={15}
          >
            <Box marginTop={2}>
              <Sidebar
                pages={pages}
                name={name}
                openNav={open}
                onCloseNav={() => setOpen(false)}
              />
            </Box>
            <Box
              width={{ xs: "70%", sm: "80%" }}
              marginTop={{ xs: "9%", sm: "0%" }}
            >
              <Box height="100%">
                {children}
                <Divider />
                <Container paddingY={1}>
                  <Footer />
                </Container>
              </Box>
            </Box>
          </Box>
        </main>
      </Box>
    </React.Fragment>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
  setThemePalette: PropTypes.func.isRequired,
  paletteType: PropTypes.string.isRequired,
};

export default DashboardLayout;
