import React from "react";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import logo from "../../../../assets/logo/logo.png";

const Topbar = () => {
  const theme = useTheme();

  const [state, setState] = React.useState({
    right: false,
  });

  const handleDrawerClose = () => {
    setState(false);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    ></Box>
  );

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));
  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={"100%"}
      marginBottom={{ xs: 2, sm: 1 }}
      marginTop={{ xs: 2, sm: 1 }}
      id="back-to-top-anchor"
    >
      <Box
        sx={{ display: { xs: "none", md: "flex" } }}
        marginLeft={{ xs: "0%", sm: "0%" }}
        width={135}
      >
        <Box component="a" underline="none" href="/" title="BudgIT">
          <img src={logo} alt="logo" width={"100%"} />
        </Box>
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" } }} width={"100%"}>
        <div>
          <AppBar
            position="fixed"
            zIndex={theme.zIndex.drawer + 1}
            sx={{
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Toolbar>
              <Box
                component="a"
                underline="none"
                href="/"
                title="BudgIT"
                width={130}
                marginRight={24}
                marginTop={1}
                marginBottom={1}
              >
                <Box
                  component="img"
                  height={"6vh"}
                  sx={{
                    width: "100%",
                  }}
                  src={logo}
                  alt={"logo"}
                />
              </Box>
              {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <IconButton onClick={toggleDrawer(anchor, true)}>
                    <MenuIcon fontSize="large" sx={{ color: "#1C4E63" }} />
                  </IconButton>
                  <SwipeableDrawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                  >
                    <DrawerHeader>
                      <IconButton onClick={handleDrawerClose}>
                        <CloseIcon sx={{ color: "#049593" }} />
                      </IconButton>
                    </DrawerHeader>
                    <Divider />
                    {list(anchor)}
                    <Divider />
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </Toolbar>
          </AppBar>
        </div>
      </Box>
    </Box>
  );
};

export default Topbar;
