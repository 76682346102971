import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "../../common/Container";

const DashboardApp = () => {
  return (
    <Box
      marginTop={{ xs: "1%", sm: "2%" }}
      marginBottom={{ xs: "1%", sm: "36%" }}
    >
      <Box display={"flex"} alignItems={"center"} height={"100%"}>
        <Container maxWidth={700}>
          <Box>
            <Box marginBottom={4} marginTop={{ xs: "2%", md: "0%" }}>
              <Typography
                variant="h5"
                color={"#049593"}
                align={"left"}
                fontWeight={700}
                gutterBottom
                marginBottom={{ xs: "15%", sm: "2%" }}
              >
                The Dashboard
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default DashboardApp;
