import { uiInfoConstants } from "../constants";

export const leagueInfo = (state = {}, action) => {
  switch (action.type) {
    case uiInfoConstants.LEAGUE_INFO_REQUEST:
      return { loading: true };
    case uiInfoConstants.LEAGUE_INFO_SUCCESS:
      return { data: action.data, loading: false };
    case uiInfoConstants.LEAGUE_INFO_FAILURE:
      return { loading: false };
    default:
      return state;
  }
};

export function period(state = {}, action) {
  switch (action.type) {
    case uiInfoConstants.PERIOD_INFO_REQUEST:
      return { loading: true };
    case uiInfoConstants.PERIOD_INFO_SUCCESS:
      return { data: action.data, loading: false };
    case uiInfoConstants.PERIOD_INFO_FAILURE:
      return { loading: false };
    default:
      return state;
  }
}

export function publishedQuarter(state = {}, action) {
  switch (action.type) {
    case uiInfoConstants.QUARTER_INFO_REQUEST:
      return { loading: true };
    case uiInfoConstants.QUARTER_INFO_SUCCESS:
      return { data: action.data, loading: false };
    case uiInfoConstants.QUARTER_INFO_FAILURE:
      return { loading: false };
    default:
      return state;
  }
}

export function editPublishQuarter(state = {}, action) {
  switch (action.type) {
    case uiInfoConstants.QUARTER_INFO_REQUEST:
      return { isSaving: true };
    case uiInfoConstants.QUARTER_INFO_SUCCESS:
      return { saved: true };
    case uiInfoConstants.QUARTER_INFO_FAILURE:
      return {};
    default:
      return state;
  }
}

export function periodByID(state = {}, action) {
  switch (action.type) {
    case uiInfoConstants.PERIOD_INFO_REQUEST:
      return { loading: true };
    case uiInfoConstants.PERIOD_INFO_SUCCESS:
      return { data: action.data, loading: false };
    case uiInfoConstants.PERIOD_INFO_FAILURE:
      return { loading: false };
    default:
      return state;
  }
}

export function analysisPeriodByID(state = {}, action) {
  switch (action.type) {
    case uiInfoConstants.ANALYSIS_INFO_REQUEST:
      return { loading: true };
    case uiInfoConstants.ANALYSIS_INFO_SUCCESS:
      return { data: action.data, loading: false };
    case uiInfoConstants.ANALYSIS_INFO_FAILURE:
      return { loading: false };
    default:
      return state;
  }
}

export function states(state = {}, action) {
  switch (action.type) {
    case uiInfoConstants.STATE_INFO_REQUEST:
      return { loading: true };
    case uiInfoConstants.STATE_INFO_SUCCESS:
      return { data: action.data, loading: false };
    case uiInfoConstants.STATE_INFO_FAILURE:
      return { loading: false };
    default:
      return state;
  }
}
