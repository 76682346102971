export const light = {
  alternate: {
    main: "#f7f9fc",
    dark: "#edf1f7",
  },
  cardShadow: "rgba(23, 70, 161, .11)",
  mode: "light",
  primary: {
    main: "#049593",
    light: "#049593",
    dark: "#049593",
    contrastText: "#fff",
  },
  secondary: {
    light: "#e7e0ff",
    main: "#b4aee8",
    dark: "#837fb6",
    contrastText: "rgba(0, 0, 0, 0.87)",
  },
  text: {
    primary: "#2d3748",
    secondary: "#646e73",
  },
  divider: "rgba(0, 0, 0, 0.12)",
  background: {
    paper: "#fff",
    default: "#fff",
    level2: "#f5f5f5",
    level1: "#fff",
  },
  dashboard: {
    bg1: "#fef2c2",
    bg2: "#554971",
    bg3: "#d6e9f9",
  },
};

export const dark = {
  alternate: {
    main: "#1a2138",
    dark: "#151a30",
  },
  cardShadow: "rgba(0, 0, 0, .11)",
  common: {
    black: "#000",
    white: "#fff",
  },
  mode: "dark",
  primary: {
    main: "#049593",
    light: "#049593",
    dark: "#049593",
    contrastText: "#fff",
  },
  secondary: {
    light: "#e7e0ff",
    main: "#b4aee8",
    dark: "#837fb6",
    contrastText: "rgba(0, 0, 0, 0.87)",
  },
  text: {
    primary: "#EEEEEF",
    secondary: "#AEB0B4",
  },
  divider: "rgba(255, 255, 255, 0.12)",
  background: {
    paper: "#222B45",
    default: "#222B45",
    level2: "#333",
    level1: "#2D3748",
  },
};
