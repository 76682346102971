import { uiInfoConstants } from "../constants";
import { uiInfoService } from "../services";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
  alertActions,
  userActions,
} from "./";
import { history } from "../helpers";

export const uiInfoActions = {
  period,
  publishedQuarter,
  editPublishQuarter,
  periodByID,
  state,
  getLeagueData,
  analysisPeriodByID,
};

function period() {
  return (dispatch) => {
    dispatch(request());
    uiInfoService.getPeriod().then(
      (res) => {
        const period = res;
        dispatch(success(period));
      },
      (error) => {
        console.log(error);
        dispatch(failure());
        dispatch(showErrorSnackbar("error loading league period!"));
      }
    );
  };

  function request() {
    return { type: uiInfoConstants.PERIOD_INFO_REQUEST };
  }
  function success(data) {
    return { type: uiInfoConstants.PERIOD_INFO_SUCCESS, data };
  }
  function failure() {
    return { type: uiInfoConstants.PERIOD_INFO_FAILURE };
  }
}
function publishedQuarter() {
  return (dispatch) => {
    dispatch(request());
    uiInfoService.getPublishedQuarter().then(
      (res) => {
        const quarters = res;
        dispatch(success(quarters));
      },
      (error) => {
        console.log(error);
        dispatch(failure());
        dispatch(showErrorSnackbar("error loading quarters!"));
      }
    );
  };

  function request() {
    return { type: uiInfoConstants.QUARTER_INFO_REQUEST };
  }
  function success(data) {
    return { type: uiInfoConstants.QUARTER_INFO_SUCCESS, data };
  }
  function failure() {
    return { type: uiInfoConstants.QUARTER_INFO_FAILURE };
  }
}

function editPublishQuarter(token, data) {
  return (dispatch) => {
    dispatch(request());
    return uiInfoService.editQuarter(data, token).then(
      (res) => {
        dispatch(success());
        dispatch(showSuccessSnackbar("Quarter Published Successfully!"));
        history.push("/dashboard/publish-quarter");
        history.go("/dashboard/publish-quarter");
      },
      (error) => {
        dispatch(failure(error));
        if (error === 401) {
          userActions.expiredSession();
        } else {
          alertActions.error(error.toString());
        }
      }
    );
  };

  function request() {
    return { type: uiInfoConstants.QUARTER_INFO_REQUEST };
  }
  function success(data) {
    return { type: uiInfoConstants.QUARTER_INFO_SUCCESS, data };
  }
  function failure(error) {
    return { type: uiInfoConstants.QUARTER_INFO_FAILURE, error };
  }
}

function periodByID() {
  return (dispatch) => {
    dispatch(request());
    uiInfoService.getPeriodByID().then(
      (res) => {
        const period = res;
        dispatch(success(period));
      },
      (error) => {
        console.log(error);
        dispatch(failure());
        dispatch(showErrorSnackbar("error loading league scores by quarter!"));
      }
    );
  };

  function request() {
    return { type: uiInfoConstants.PERIOD_INFO_REQUEST };
  }
  function success(data) {
    return { type: uiInfoConstants.PERIOD_INFO_SUCCESS, data };
  }
  function failure() {
    return { type: uiInfoConstants.PERIOD_INFO_FAILURE };
  }
}

function analysisPeriodByID() {
  return (dispatch) => {
    dispatch(request());
    uiInfoService.getAnalysisPeriodByID().then(
      (res) => {
        const analysis = res;
        dispatch(success(analysis));
      },
      (error) => {
        console.log(error);
        dispatch(failure());
        dispatch(showErrorSnackbar("error loading state analysis by quarter!"));
      }
    );
  };

  function request() {
    return { type: uiInfoConstants.ANALYSIS_INFO_REQUEST };
  }
  function success(data) {
    return { type: uiInfoConstants.ANALYSIS_INFO_SUCCESS, data };
  }
  function failure() {
    return { type: uiInfoConstants.ANALYSIS_INFO_FAILURE };
  }
}

function state() {
  return (dispatch) => {
    dispatch(request());
    uiInfoService.getState().then(
      (res) => {
        const states = res;
        dispatch(success(states));
      },
      (error) => {
        console.log(error);
        dispatch(failure());
        dispatch(showErrorSnackbar("error loading league states!"));
      }
    );
  };

  function request() {
    return { type: uiInfoConstants.STATE_INFO_REQUEST };
  }
  function success(data) {
    return { type: uiInfoConstants.STATE_INFO_SUCCESS, data };
  }
  function failure() {
    return { type: uiInfoConstants.STATE_INFO_FAILURE };
  }
}

function getLeagueData() {
  return (dispatch) => {
    dispatch(request());
    uiInfoService.getLeagueScore().then(
      (res) => {
        const league_data = res;
        dispatch(success(league_data));
      },
      (error) => {
        console.log(error);
        dispatch(failure());
        dispatch(showErrorSnackbar("error loading league data!"));
      }
    );
  };

  function request() {
    return { type: uiInfoConstants.LEAGUE_INFO_REQUEST };
  }
  function success(data) {
    return { type: uiInfoConstants.LEAGUE_INFO_SUCCESS, data };
  }
  function failure() {
    return { type: uiInfoConstants.LEAGUE_INFO_FAILURE };
  }
}
