import { userConstants } from "../constants";
import * as _ from "lodash";

const _user = localStorage.getItem("budgit_user");
const _token = localStorage.getItem("budgit_token");

const user = !_.isEmpty(_user) && !_user ? JSON.parse(_user) : {};
const token = !_.isEmpty(_token) && !_token ? JSON.parse(_token) : {};

const initialState = !_.isEmpty(token) ? { loggedIn: true, user, token } : {};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
        token: action.token,
      };
    case userConstants.LOGIN_FAILURE:
      return {};
    case userConstants.REGISTER_REQUEST:
      return { creating: true };
    case userConstants.REGISTER_SUCCESS:
      return { creating: false };
    case userConstants.REGISTER_FAILURE:
      return { creating: false };
    case userConstants.LOGOUT:
      return {};
    default:
      return state;
  }
}
