import React, { useState, useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { uiInfoService } from "../../../services";

const initialTable = [
  "mtef",
  "proposed",
  "approved",
  "citizen",
  "quarterly",
  "procurement",
  "website",
];

const LatestTable = ({ selectedPeriod }) => {
  const [sortedStates, setSortedStates] = useState([]);
  const [isCustomTable, setIsCustomTable] = useState(false);

  const fetchLeagueData = useCallback(
    async (period_id) => {
      try {
        if (period_id) {
          const data = await uiInfoService.getPeriodByID(period_id);
          refreshSortedStates(data.period_id);
          if ([1, 2, 5, 6, 9, 10, 13, 14, 17, 18].includes(period_id)) {
            setIsCustomTable("initial_table");
          } else {
            setIsCustomTable(false);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    [uiInfoService, sortedStates]
  );

  useEffect(() => {
    fetchLeagueData(selectedPeriod);
  }, [selectedPeriod, fetchLeagueData]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#1C4E63",
      color: theme.palette.common.white,
      fontWeight: 700,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 24,
      fontWeight: 700,
    },
  }));

  const sumLastColumn = (state) => {
    if (isCustomTable === "initial_table") {
      return initialTable.reduce(
        (sum, column) =>
          sum +
          (typeof state.data[column] === "number" ? state.data[column] : 0),
        0
      );
    } else {
      return (
        (Object.values(state.data).reduce(
          (sum, value) => sum + (typeof value === "number" ? value : 0),
          0
        ) /
          133) *
        100
      ).toFixed();
    }
  };

  const refreshSortedStates = (data) => {
    if (data && data.states) {
      const newSortedStates = data.states
        .slice()
        .sort((a, b) => sumLastColumn(b) - sumLastColumn(a));
      setSortedStates(newSortedStates);
    } else {
      setSortedStates([]);
    }
  };

  const getRowTextColor = (index) => {
    if (index < 9) {
      return "#16FF00";
    } else if (index >= sortedStates.length - 3) {
      return "#fc2d2d";
    } else {
      return "#FDFF00";
    }
  };

  const getLatestQuarter = () => {
    switch (selectedPeriod) {
      case 4:
        return "Q4 2023";
      case 5:
        return "Q1 2024";
      case 6:
        return "Q2 2024";
      case 7:
        return "Q3 2024";
      case 8:
        return "Q4 2024";
      case 9:
        return "Q1 2025";
      case 10:
        return "Q2 2025";
      case 11:
        return "Q3 2025";
      case 12:
        return "Q4 2025";
      default:
        return "";
    }
  };

  return (
    <Box marginTop={{ xs: "15%", sm: "2%" }}>
      <Typography
        variant="h3"
        color={"#049593"}
        align={"left"}
        fontWeight={700}
        gutterBottom
        marginBottom={{ xs: "15%", sm: "2%" }}
      >
        States Fiscal Transparency League Table
      </Typography>
      <Box marginBottom={{ xs: "10%", sm: "2%" }}>
        <Box
          width={{ xs: "50%", sm: "25%" }}
          marginLeft={{ xs: "0%", sm: "0%" }}
        >
          <Typography
            variant={"h6"}
            sx={{ marginBottom: 1 }}
            fontWeight={700}
            color={"#1C4E63"}
          >
            <strong>Latest Quarter:</strong> {getLatestQuarter()}
          </Typography>
        </Box>
      </Box>
      {isCustomTable === "initial_table" ? (
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">S/N</StyledTableCell>
                <StyledTableCell align="left">Name of States</StyledTableCell>
                <StyledTableCell align="center">MTEF (12)</StyledTableCell>
                <StyledTableCell align="center">
                  Proposed Budget (13)
                </StyledTableCell>
                <StyledTableCell align="center">
                  Approved Budget (21)
                </StyledTableCell>
                <StyledTableCell align="center">
                  Citizens' Budget (15)
                </StyledTableCell>
                <StyledTableCell align="center">
                  Quarterly BIR (16)
                </StyledTableCell>
                <StyledTableCell align="center">
                  e-Procurement Portal (11)
                </StyledTableCell>
                <StyledTableCell align="center">
                  State Website with Fiscal Data Repository (12)
                </StyledTableCell>
                <StyledTableCell align="center">Score (%)</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedStates.length === 0 ? (
                <TableRow>
                  <StyledTableCell align="left" colSpan={10}>
                    <Typography
                      variant="h4"
                      color={"#049593"}
                      align="center"
                      fontWeight={700}
                    >
                      No League Score
                    </Typography>
                  </StyledTableCell>
                </TableRow>
              ) : (
                sortedStates.map((state, index) => (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor: "#1C4E63",
                    }}
                  >
                    <TableCell
                      style={{
                        color: getRowTextColor(index),
                        fontWeight: 600,
                        fontSize: 14,
                        textAlign: "center",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: getRowTextColor(index),
                        fontWeight: 600,
                        fontSize: 14,
                      }}
                    >
                      {state.state_name}
                    </TableCell>
                    <TableCell
                      style={{
                        color: getRowTextColor(index),
                        fontWeight: 600,
                        fontSize: 14,
                        textAlign: "center",
                      }}
                    >
                      {state.data.mtef}
                    </TableCell>
                    <TableCell
                      style={{
                        color: getRowTextColor(index),
                        fontWeight: 600,
                        fontSize: 14,
                        textAlign: "center",
                      }}
                    >
                      {state.data.proposed}
                    </TableCell>
                    <TableCell
                      style={{
                        color: getRowTextColor(index),
                        fontWeight: 600,
                        fontSize: 14,
                        textAlign: "center",
                      }}
                    >
                      {state.data.approved}
                    </TableCell>
                    <TableCell
                      style={{
                        color: getRowTextColor(index),
                        fontWeight: 600,
                        fontSize: 14,
                        textAlign: "center",
                      }}
                    >
                      {state.data.citizen}
                    </TableCell>
                    <TableCell
                      style={{
                        color: getRowTextColor(index),
                        fontWeight: 600,
                        fontSize: 14,

                        textAlign: "center",
                      }}
                    >
                      {state.data.quarterly}
                    </TableCell>
                    <TableCell
                      style={{
                        color: getRowTextColor(index),
                        fontWeight: 600,
                        fontSize: 14,
                        textAlign: "center",
                      }}
                    >
                      {state.data.procurement}
                    </TableCell>
                    <TableCell
                      style={{
                        color: getRowTextColor(index),
                        fontWeight: 600,
                        fontSize: 14,
                        textAlign: "center",
                      }}
                    >
                      {state.data.website}
                    </TableCell>
                    <TableCell
                      style={{
                        color: getRowTextColor(index),
                        fontWeight: 600,
                        fontSize: 14,
                        textAlign: "center",
                      }}
                    >
                      {sumLastColumn(state)}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">S/N</StyledTableCell>
                <StyledTableCell align="left">Name of States</StyledTableCell>
                <StyledTableCell align="center">MTEF (12)</StyledTableCell>
                <StyledTableCell align="center">
                  Proposed Budget (13)
                </StyledTableCell>
                <StyledTableCell align="center">
                  Approved Budget (21)
                </StyledTableCell>
                <StyledTableCell align="center">
                  Citizens' Budget (15)
                </StyledTableCell>
                <StyledTableCell align="center">
                  Quarterly BIR (16)
                </StyledTableCell>
                <StyledTableCell align="center">
                  Audit Report (15)
                </StyledTableCell>
                <StyledTableCell align="center">
                  Accountant General’s Report (18)
                </StyledTableCell>
                <StyledTableCell align="center">
                  e-Procurement Portal (11)
                </StyledTableCell>
                <StyledTableCell align="center">
                  State Website with Fiscal Data (12)
                </StyledTableCell>
                <StyledTableCell align="center">Score (%)</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedStates.length === 0 ? (
                <TableRow>
                  <StyledTableCell align="left" colSpan={10}>
                    <Typography
                      variant="h4"
                      color={"#049593"}
                      align="center"
                      fontWeight={700}
                    >
                      No League Score
                    </Typography>
                  </StyledTableCell>
                </TableRow>
              ) : (
                sortedStates.map((state, index) => (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor: "#1C4E63",
                    }}
                  >
                    <TableCell
                      style={{
                        color: getRowTextColor(index),
                        fontWeight: 600,
                        fontSize: 14,
                        textAlign: "center",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: getRowTextColor(index),
                        fontWeight: 600,
                        fontSize: 14,
                      }}
                    >
                      {state.state_name}
                    </TableCell>
                    <TableCell
                      style={{
                        color: getRowTextColor(index),
                        fontWeight: 600,
                        fontSize: 14,
                        textAlign: "center",
                      }}
                    >
                      {state.data.mtef}
                    </TableCell>
                    <TableCell
                      style={{
                        color: getRowTextColor(index),
                        fontWeight: 600,
                        fontSize: 14,
                        textAlign: "center",
                      }}
                    >
                      {state.data.proposed}
                    </TableCell>
                    <TableCell
                      style={{
                        color: getRowTextColor(index),
                        fontWeight: 600,
                        fontSize: 14,
                        textAlign: "center",
                      }}
                    >
                      {state.data.approved}
                    </TableCell>
                    <TableCell
                      style={{
                        color: getRowTextColor(index),
                        fontWeight: 600,
                        fontSize: 14,
                        textAlign: "center",
                      }}
                    >
                      {state.data.citizen}
                    </TableCell>
                    <TableCell
                      style={{
                        color: getRowTextColor(index),
                        fontWeight: 600,
                        fontSize: 14,
                        textAlign: "center",
                      }}
                    >
                      {state.data.quarterly}
                    </TableCell>
                    <TableCell
                      style={{
                        color: getRowTextColor(index),
                        fontWeight: 600,
                        fontSize: 14,
                        textAlign: "center",
                      }}
                    >
                      {state.data.audit}
                    </TableCell>
                    <TableCell
                      style={{
                        color: getRowTextColor(index),
                        fontWeight: 600,
                        fontSize: 14,
                        textAlign: "center",
                      }}
                    >
                      {state.data.ag_report}
                    </TableCell>
                    <TableCell
                      style={{
                        color: getRowTextColor(index),
                        fontWeight: 600,
                        fontSize: 14,
                        textAlign: "center",
                      }}
                    >
                      {state.data.procurement}
                    </TableCell>
                    <TableCell
                      style={{
                        color: getRowTextColor(index),
                        fontWeight: 600,
                        fontSize: 14,
                        textAlign: "center",
                      }}
                    >
                      {state.data.website}
                    </TableCell>
                    <TableCell
                      style={{
                        color: getRowTextColor(index),
                        fontWeight: 600,
                        fontSize: 14,
                        textAlign: "center",
                      }}
                    >
                      {sumLastColumn(state)}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default LatestTable;
