import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { uiInfoService } from "../../../services";

const StateAnalysis = ({ quarters }) => {
  const [selectedPeriod, setSelectedPeriod] = useState(
    quarters.length > 0 ? quarters[0].period_id : null
  );
  const [analysisData, setAnalysisData] = useState([]);

  const fetchLeagueData = async (period_id) => {
    try {
      if (period_id) {
        const data = await uiInfoService.getAnalysisPeriodByID(period_id);
        setAnalysisData(data.period_id.states);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchLeagueData(selectedPeriod);
  }, [selectedPeriod]);

  const handleChangePeriod = (event) => {
    setSelectedPeriod(event.target.value);
  };
  return (
    <Grid container margin={{ xs: "15% 0%", sm: "5% 0% 2% 0%" }}>
      <Grid item xs={12} sm={12}>
        <Typography
          variant="h3"
          color={"#049593"}
          align={"left"}
          fontWeight={700}
          gutterBottom
          paddingBottom={{ xs: "5%", sm: "2%" }}
        >
          State-by-State Appraisals
        </Typography>
        <Typography
          variant="body1"
          color={"#4f4f4f"}
          align={"left"}
          paddingBottom={{ xs: "8%", sm: "2%" }}
          gutterBottom
        >
          This page describes areas where states fell short of the minimum
          requirements of fiscal transparency during the review period and have
          also made significant progress toward meeting the minimum
          requirements, the page also includes a brief description of such
          progress.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Box marginBottom={{ xs: "15%", sm: "4%" }}>
          <Typography
            variant={"subtitle2"}
            sx={{ marginBottom: 1 }}
            fontWeight={700}
            color={"#1C4E63"}
          >
            Select Quarter
          </Typography>
          <Box
            width={{ xs: "50%", sm: "25%" }}
            marginLeft={{ xs: "0%", sm: "0%" }}
          >
            <FormControl fullWidth>
              <Select
                value={selectedPeriod}
                onChange={handleChangePeriod}
                fullWidth
              >
                {quarters.map((periodId) => (
                  <MenuItem key={periodId.period_id} value={periodId.period_id}>
                    {periodId.quarter}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Grid>
      {analysisData.length === 0 ? (
        <Grid item xs={12} sm={12} paddingBottom={{ xs: "15%", sm: "5%" }}>
          <Typography
            variant="h4"
            color={"#049593"}
            align="center"
            fontWeight={700}
          >
            No State-by-State Appraisal
          </Typography>
        </Grid>
      ) : (
        analysisData.map((detail, index) => (
          <Grid
            item
            xs={12}
            sm={12}
            key={index}
            paddingBottom={{ xs: "15%", sm: "5%" }}
          >
            <Typography
              variant="h5"
              color={"#049593"}
              fontWeight={700}
              gutterBottom
            >
              <Typography
                component={"span"}
                color="#D7A036"
                fontWeight={800}
                variant={"h4"}
              >
                {index + 1}{" "}
              </Typography>
              {detail.state_name} State
            </Typography>
            <Typography variant="body1" color={"#4f4f4f"} lineHeight={1.5}>
              {detail.state_analysis}
            </Typography>
          </Grid>
        ))
      )}
    </Grid>
  );
};

export default StateAnalysis;
