import React from "react";
import Typography from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import banner from "../../../assets/others/about.jpg";

const Overview = () => {
  return (
    <>
      <Grid container margin={{ xs: "8% 0%", sm: "4% 0%" }}>
        <Grid xs={12} sm={6} display={{ xs: "none", sm: "inline" }}>
          <img src={banner} alt="About_Img" width={"90%"} />
        </Grid>
        <Grid
          xs={12}
          sm={6}
          paddingRight={{ xs: "0%", sm: "3%" }}
          marginTop={{ xs: "5%", sm: "0%" }}
        >
          <Typography
            variant={"body1"}
            color={"#4f4f4f"}
            paddingBottom={"4%"}
            paragraph
            lineHeight={1.5}
          >
            The initiative is a build up on the recently concluded World Bank's
            State Fiscal Transparency, Accountability and Sustainability (SFTAS)
            Program, which promoted fiscal transparency, and facilitated
            accountability in public resource management. Consequently, BudgIT’s
            States Fiscal Transparency League initiative aims to sustain the
            gains of the World Bank’s SFTAS by tracking how well States continue
            to maintain fiscal transparency, accountability, accessibility and
            effective public finance management even after the stipends have
            dried up. This program will be a quarterly assessment of how well
            the states are performing.
          </Typography>
          <Typography
            variant={"body1"}
            color={"#4f4f4f"}
            paddingBottom={"4%"}
            paragraph
            lineHeight={1.5}
          >
            It is important for all state governments to have functional and
            up-to-date websites, as this is imperative to enable the team to
            extract the required information to aid the process. The appraisal
            will focus on the underlisted:
          </Typography>
          <Typography
            variant="body1"
            color={"#4f4f4f"}
            paragraph
            paddingBottom={"2%"}
          >
            <Typography
              component={"span"}
              color="#049593"
              fontWeight={800}
              variant={"body1"}
              lineHeight={1.5}
            >
              &#8281; {""}
            </Typography>{" "}
            Medium-Term Expenditure Framework (MTEF)
          </Typography>
          <Typography
            variant="body1"
            color={"#4f4f4f"}
            paragraph
            paddingBottom={"2%"}
          >
            <Typography
              component={"span"}
              color="#049593"
              fontWeight={800}
              variant={"body1"}
              lineHeight={1.5}
            >
              &#8281; {""}
            </Typography>{" "}
            Proposed Budget
          </Typography>
          <Typography
            variant="body1"
            color={"#4f4f4f"}
            paragraph
            paddingBottom={"2%"}
          >
            <Typography
              component={"span"}
              color="#049593"
              fontWeight={800}
              variant={"body1"}
              lineHeight={1.5}
            >
              &#8281; {""}
            </Typography>{" "}
            Approved Budget
          </Typography>
          <Typography
            variant="body1"
            color={"#4f4f4f"}
            paragraph
            paddingBottom={"2%"}
          >
            <Typography
              component={"span"}
              color="#049593"
              fontWeight={800}
              variant={"body1"}
              lineHeight={1.5}
            >
              &#8281; {""}
            </Typography>{" "}
            Citizens’ Budget
          </Typography>
          <Typography
            variant="body1"
            color={"#4f4f4f"}
            paragraph
            paddingBottom={"2%"}
          >
            <Typography
              component={"span"}
              color="#049593"
              fontWeight={800}
              variant={"body1"}
              lineHeight={1.5}
            >
              &#8281; {""}
            </Typography>{" "}
            Budget Implementation Reports (BIR)
          </Typography>
          <Typography
            variant="body1"
            color={"#4f4f4f"}
            paragraph
            paddingBottom={"2%"}
          >
            <Typography
              component={"span"}
              color="#049593"
              fontWeight={800}
              variant={"body1"}
              lineHeight={1.5}
            >
              &#8281; {""}
            </Typography>{" "}
            eProcurement portal
          </Typography>
          <Typography
            variant="body1"
            color={"#4f4f4f"}
            paragraph
            paddingBottom={"2%"}
          >
            <Typography
              component={"span"}
              color="#049593"
              fontWeight={800}
              variant={"body1"}
              lineHeight={1.5}
            >
              &#8281; {""}
            </Typography>{" "}
            Audit report
          </Typography>
          <Typography
            variant="body1"
            color={"#4f4f4f"}
            paragraph
            paddingBottom={"2%"}
          >
            <Typography
              component={"span"}
              color="#049593"
              fontWeight={800}
              variant={"body1"}
              lineHeight={1.5}
            >
              &#8281; {""}
            </Typography>{" "}
            Accountant General’s Report/Financial Statement
          </Typography>
          <Typography
            variant="body1"
            color={"#4f4f4f"}
            paragraph
            paddingBottom={"2%"}
          >
            <Typography
              component={"span"}
              color="#049593"
              fontWeight={800}
              variant={"body1"}
              lineHeight={1.5}
            >
              &#8281; {""}
            </Typography>{" "}
            States Website with Fiscal Repository
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default Overview;
