import { scoreConstants } from "../constants";

export function newLeagueScore(state = {}, action) {
  switch (action.type) {
    case scoreConstants.LEAGUE_REQUEST:
      return { isSaving: true };
    case scoreConstants.LEAGUE_SUCCESS:
      return { saved: true };
    case scoreConstants.LEAGUE_FAILURE:
      return {};
    default:
      return state;
  }
}

export function newStateAnalysis(state = {}, action) {
  switch (action.type) {
    case scoreConstants.ANALYSIS_REQUEST:
      return { isSaving: true };
    case scoreConstants.ANALYSIS_SUCCESS:
      return { saved: true };
    case scoreConstants.ANALYSIS_FAILURE:
      return {};
    default:
      return state;
  }
}

export function editStateAnalysis(state = {}, action) {
  switch (action.type) {
    case scoreConstants.ANALYSIS_REQUEST:
      return { isSaving: true };
    case scoreConstants.ANALYSIS_SUCCESS:
      return { saved: true };
    case scoreConstants.ANALYSIS_FAILURE:
      return {};
    default:
      return state;
  }
}

export function newPeriod(state = {}, action) {
  switch (action.type) {
    case scoreConstants.PERIOD_REQUEST:
      return { isSaving: true };
    case scoreConstants.PERIOD_SUCCESS:
      return { saved: true };
    case scoreConstants.PERIOD_FAILURE:
      return {};
    default:
      return state;
  }
}

export function editScores(state = {}, action) {
  switch (action.type) {
    case scoreConstants.LEAGUE_REQUEST:
      return { isSaving: true };
    case scoreConstants.LEAGUE_SUCCESS:
      return { saved: true };
    case scoreConstants.LEAGUE_FAILURE:
      return {};
    default:
      return state;
  }
}
