import React from "react";
import { Switch, Route } from "react-router-dom";
import { PrivateRoute } from "./helpers";
import WithLayout from "./WithLayout";
import DashboardLayout from "./layouts/DashboardLayout";
import MainLayout from "./layouts/Main";
import SimpleLayout from "./layouts/Simple";
import Home from "./components/Home";
import About from "./components/About";
import Latest from "./components/Latest";
import Analysis from "./components/Analysis";
import Methodology from "./components/Methodology";
import Indicators from "./components/Indicators";
import LoginPage from "./components/Login";
import LeagueTableForm from "./components/Admin/scores/LeagueTableForm";
import StateAnalysis from "./components/Admin/analysis/StateAnalysis";
import PublishQuarter from "./components/Admin/editQuarter/PublishQuarter";
import AddPeriod from "./components/Admin/period/AddPeriod";
import { AddUser, DashboardApp } from "./components/Admin";
import ListLeagueScore from "./components/Admin/editLeagueScores/ListLeagueScore";
import StateAnalysisList from "./components/Admin/editAnalysis/StateAnalysisList";

const Routes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={(matchProps) => (
          <WithLayout {...matchProps} component={Home} layout={MainLayout} />
        )}
      />
      <Route
        exact
        path="/latest-ranking"
        render={(matchProps) => (
          <WithLayout {...matchProps} component={Latest} layout={MainLayout} />
        )}
      />
      <Route
        exact
        path="/state-by-state-appraisals"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={Analysis}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/overview"
        render={(matchProps) => (
          <WithLayout {...matchProps} component={About} layout={MainLayout} />
        )}
      />
      <Route
        exact
        path="/league-scoring-methodology"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={Methodology}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/background-indicators"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={Indicators}
            layout={MainLayout}
          />
        )}
      />
      <Route
        exact
        path="/login"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={LoginPage}
            layout={SimpleLayout}
          />
        )}
      />
      <PrivateRoute
        exact
        path="/dashboard/app"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            pageTitle={"Dashboard"}
            component={DashboardApp}
            layout={DashboardLayout}
          />
        )}
      >
        <DashboardApp />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard/add-league-score"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            pageTitle={"Add League Scores"}
            component={LeagueTableForm}
            layout={DashboardLayout}
          />
        )}
      >
        <LeagueTableForm />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard/edit-league-score"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            pageTitle={"Edit League Scores"}
            component={ListLeagueScore}
            layout={DashboardLayout}
          />
        )}
      >
        <ListLeagueScore />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard/edit-state-analysis"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            pageTitle={"Edit State Analysis"}
            component={StateAnalysisList}
            layout={DashboardLayout}
          />
        )}
      >
        <StateAnalysisList />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard/add-state-analysis"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            pageTitle={"Add State Analysis"}
            component={StateAnalysis}
            layout={DashboardLayout}
          />
        )}
      >
        <StateAnalysis />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard/publish-quarter"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            pageTitle={"Publish Quarter"}
            component={PublishQuarter}
            layout={DashboardLayout}
          />
        )}
      >
        <PublishQuarter />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard/add-quarter"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            pageTitle={"Add Quarter"}
            component={AddPeriod}
            layout={DashboardLayout}
          />
        )}
      >
        <AddPeriod />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard/add-user"
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            pageTitle={"Add User"}
            component={AddUser}
            layout={DashboardLayout}
          />
        )}
      >
        <AddUser />
      </PrivateRoute>
    </Switch>
  );
};

export default Routes;
