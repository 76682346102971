import * as config from "../config";
import axios from "axios";

export function getHeaders(token) {
  return token
    ? {
        Authorization: `Bearer ${token?.AccessToken}`,
        "Refresh-token": token?.RefreshToken,
      }
    : {};
}

export function getRequest(token, endpoint) {
  const headers = getHeaders(token);
  return axios({
    method: "get",
    url: `${config.BASE_URL}/api/${endpoint}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err && err.response && err.response.status === 401) {
        return Promise.reject(401);
      } else if (err.response) {
        return Promise.reject(err.response.data.error);
      }
      return Promise.reject(err);
    });
}

export function getData(endpoint) {
  return axios({
    method: "get",
    url: `${config.BASE_URL}/api/${endpoint}`,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err && err.response && err.response.status === 401) {
        return Promise.reject(401);
      } else if (err.response) {
        return Promise.reject(err.response.data.error);
      }
      return Promise.reject(err);
    });
}

export function postRequest(data, token, endpoint) {
  const headers = getHeaders(token);
  return axios({
    method: "post",
    url: `${config.BASE_URL}/api/${endpoint}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err && err.response && err.response.status === 401) {
        return Promise.reject(401);
      } else if (err.response) {
        return Promise.reject(err.response.data.error);
      }

      return Promise.reject(err);
    });
}

export function editRequest(data, token, endpoint) {
  const headers = getHeaders(token);
  return axios({
    method: "put",
    url: `${config.BASE_URL}/api/${endpoint}`,
    headers: headers,
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err && err.response && err.response.status === 401) {
        return Promise.reject(401);
      } else if (err.response) {
        return Promise.reject(err.response.data.error);
      }

      return Promise.reject(err);
    });
}
