import { getRequest, getData, editRequest } from "./common";

export const uiInfoService = {
  getPeriod,
  getPublishedQuarter,
  getPeriodByID,
  getState,
  getLeagueScore,
  getAnalysisPeriodByID,
  editQuarter,
};

function getPeriod() {
  return getRequest(null, "period");
}

function getPublishedQuarter() {
  return getRequest(null, "quarters");
}

function getPeriodByID(period_id) {
  return getData(`period/${period_id}`);
}

function getState() {
  return getRequest(null, "states");
}

function getLeagueScore() {
  return getRequest(null, "league-data");
}

function getAnalysisPeriodByID(period_id) {
  return getData(`state-analysis-period/${period_id}`);
}

function editQuarter(data, token) {
  return editRequest(data, token, `quarter/${data.period_id}`);
}
