// import React, { useEffect } from "react";
// import { useSelector } from "react-redux";
// import LatestTable from "./LatestTable";
// import { useDispatch } from "react-redux";
// import { uiInfoActions } from "../../../actions";

// const TheTable = () => {
//   const dispatch = useDispatch();
//   const quarters = useSelector((state) => state.publishedQuarter.data);

//   useEffect(() => {
//     dispatch(uiInfoActions.publishedQuarter());
//   }, []);

//   if (quarters) {
//     return <LatestTable quarters={quarters} />;
//   } else {
//     return <></>;
//   }
// };

// export default TheTable;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import LatestTable from "./LatestTable";
import { uiInfoActions } from "../../../actions";

const TheTable = () => {
  const dispatch = useDispatch();
  const quarters = useSelector((state) => state.publishedQuarter.data);
  const [latestQuarter, setLatestQuarter] = useState(null);

  useEffect(() => {
    dispatch(uiInfoActions.publishedQuarter());
  }, [dispatch]);

  useEffect(() => {
    if (quarters && quarters.length > 0) {
      const sortedQuarters = quarters.sort((a, b) => b.period_id - a.period_id);
      setLatestQuarter(sortedQuarters[0].period_id);
    }
  }, [quarters]);

  return latestQuarter ? <LatestTable selectedPeriod={latestQuarter} /> : null;
};

export default TheTable;
