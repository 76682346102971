import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const Score = () => {
  return (
    <Box
      width={{ xs: "100%", sm: "60%" }}
      backgroundColor={"#2B3467"}
      padding={"3%"}
      marginTop={{ xs: "10%", sm: "2%" }}
      marginLeft={"0%"}
    >
      <Box
        display={"flex"}
        justifyContent={"Space-between"}
        alignItems={"center"}
        flexWrap={"wrap"}
      >
        <Box width={{ xs: "100%", sm: "30%" }}>
          <Typography color={"#fff"} variant={"h5"} fontWeight={700}>
            SCORE ANALYSIS
          </Typography>
        </Box>
        <Box
          width={{ xs: "100%", sm: "70%" }}
          marginTop={{ xs: "5%", sm: "0%" }}
        >
          <Grid container>
            <Grid items sm={3} xs={3}>
              <Typography color={"#ccc"} variant={"body2"} fontWeight={700}>
                Description
              </Typography>
            </Grid>
            <Grid items sm={3} xs={3}>
              <Typography color={"#fff"} variant={"body2"} fontWeight={500}>
                Progressive
              </Typography>
            </Grid>
            <Grid items sm={3} xs={3}>
              <Typography color={"#fff"} variant={"body2"} fontWeight={500}>
                Average
              </Typography>
            </Grid>
            <Grid items sm={3} xs={3}>
              <Typography color={"#fff"} variant={"body2"} fontWeight={500}>
                Poor
              </Typography>
            </Grid>
            <Grid items sm={3} xs={3} paddingTop={"3%"}>
              <Typography color={"#ccc"} variant={"body2"} fontWeight={700}>
                Score
              </Typography>
            </Grid>
            <Grid items sm={3} xs={3} paddingTop={"3%"}>
              <Typography color={"#fff"} variant={"body2"} fontWeight={500}>
                71 - 100
              </Typography>
            </Grid>
            <Grid items sm={3} xs={3} paddingTop={"3%"}>
              <Typography color={"#fff"} variant={"body2"} fontWeight={500}>
                41 - 70
              </Typography>
            </Grid>
            <Grid items sm={3} xs={3} paddingTop={"3%"}>
              <Typography color={"#fff"} variant={"body2"} fontWeight={500}>
                0 -40
              </Typography>
            </Grid>
            <Grid items sm={3} xs={3} paddingTop={"3%"}>
              <Typography color={"#ccc"} variant={"body2"} fontWeight={700}>
                Color
              </Typography>
            </Grid>
            <Grid items sm={3} xs={3} paddingTop={"3%"}>
              <Box
                backgroundColor={"#16FF00"}
                width={"50%"}
                padding={"8%"}
                marginLeft={"0%"}
              ></Box>
            </Grid>
            <Grid items sm={3} xs={3} paddingTop={"3%"}>
              <Box
                backgroundColor={"#FDFF00"}
                width={"50%"}
                padding={"8%"}
                marginLeft={"0%"}
              ></Box>
            </Grid>
            <Grid items sm={3} xs={3} paddingTop={"3%"}>
              <Box
                backgroundColor={"#fc2d2d"}
                width={"50%"}
                padding={"8%"}
                marginLeft={"0%"}
              ></Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Score;
